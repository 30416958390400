import { api } from "./configs/axiosConfigs"
import { defineCancelApiObject } from "./configs/axiosUtils"

export const TransactionApi = {

    transfer: async function (data, cancel = false) {
        let AuthInformation = JSON.parse(sessionStorage.getItem('AuthInformation'));
        //console.log(AuthInformation.token);
        const response = await api.request({
            url: `Transaction/Transfer`,
            method: "POST",
            data: JSON.stringify(data), 
                headers:{
                    'Content-Type': 'application/json; charset=utf-8',
                    'Authorization' : `Bearer ${AuthInformation?.token}`
                },
            signal: cancel ? cancelApiObject[this.transfer.name].handleRequestCancellation().signal : undefined,
        })

        return response.data
    },
    getTransaction: async function (data, cancel = false) {
        let AuthInformation = JSON.parse(sessionStorage.getItem('AuthInformation'));
        const response = await api.request({
            url: `Transaction/History`,
            method: "POST",
            data: JSON.stringify(data), 
                headers:{
                    'Content-Type': 'application/json; charset=utf-8',
                    'Authorization' : `Bearer ${AuthInformation?.token}`
                },
            signal: cancel ? cancelApiObject[this.getTransaction.name].handleRequestCancellation().signal : undefined,
        })

        return response.data
    },
}

// defining the cancel API object for ProductAPI
const cancelApiObject = defineCancelApiObject(TransactionApi)