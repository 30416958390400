import React,{useEffect, useState, useRef} from "react";
import "./OtcPortalMarket.scss"
import { priceData } from "./priceData";
import { createChart, CrosshairMode } from "lightweight-charts";


function OtcPortalMarket() {


  const [open, setOpen] = useState(0);
  const [close, setClose] = useState(0);
  const [low, setLow] = useState(0);
  const [hight, setHight] = useState(0);
  const [time, setTime] = useState('');

  const chartContainerRef = useRef();
  const chart = useRef();
  const resizeObserver = useRef();
  let candleSeries;

  useEffect(() => {
      chart.current = createChart(chartContainerRef.current, {
        width: 400,
        height: 500,
        layout: {
          background: {
            color: '#ffffff'
          },
          textColor: '#000000'
        },
        grid: {
          vertLines: {
            //color: "#334158"
            color:"#d4d6d9"
          },
          horzLines: {
            //color: "#334158"
            color:"#d4d6d9"
          }
        },
        crosshair: {
          mode: CrosshairMode.Normal
        },
        priceScale: {
          borderColor: "#485c7b"
        },
        timeScale: {
          borderColor: "#485c7b"
        },
        watermark: {
          color: 'rgba(11, 94, 29, 0.4)',
          visible: true,
          text: 'cyx.finance',
          fontSize: 24,
          horzAlign: 'left',
          vertAlign: 'bottom',
        },
      });
  
      //console.log(chart.current);
  
      candleSeries = chart.current.addCandlestickSeries({
        upColor: "#4bffb5",
        downColor: "#ff4976",
        borderDownColor: "#ff4976",
        borderUpColor: "#4bffb5",
        wickDownColor: "#838ca1",
        wickUpColor: "#838ca1"
      });
  
      candleSeries.setData(priceData);
      chart.current.subscribeCrosshairMove(handleCrosshairMoved);
      
  
      // const areaSeries = chart.current.addAreaSeries({
      //   topColor: 'rgba(38,198,218, 0.56)',
      //   bottomColor: 'rgba(38,198,218, 0.04)',
      //   lineColor: 'rgba(38,198,218, 1)',
      //   lineWidth: 2
      // });
  
      // areaSeries.setData(areaData);
  
      // const volumeSeries = chart.current.addHistogramSeries({
      //   color: "#182233",
      //   lineWidth: 2,
      //   priceFormat: {
      //     type: "volume"
      //   },
      //   overlay: true,
      //   scaleMargins: {
      //     top: 0.8,
      //     bottom: 0
      //   }
      // });
  
      // volumeSeries.setData(volumeData);
    }, []);

    function handleCrosshairMoved(param) {

      if (!param.point) {
        return;
      }

      if(param.point!== undefined)
      {
        //console.log(param.point);
        let data = param.seriesData.get(candleSeries);
        //console.log(param);
        if(data !== undefined)
        {
          setHight(data.high);
          setClose(data.close);
          setLow(data.low);
          setOpen(data.open);
          setTime(data.time);
          // console.log(
          //   `A user moved the crosshair to (${data.open}, ${data.close}) point, the time is ${data.time}`
          // );
        }
      }
    }
  
    // Resize chart on container resizes.
    useEffect(() => {
      resizeObserver.current = new ResizeObserver(entries => {
        const { width, height } = entries[0].contentRect;
        chart.current.applyOptions({ width, height });
        setTimeout(() => {
          chart.current.timeScale().fitContent();
        }, 0);
      });
  
      resizeObserver.current.observe(chartContainerRef.current);
  
      return () => resizeObserver.current.disconnect();
    }, []);

  return (
        <>

          <div>CYX/USDT:</div>
          <h3>$0.91</h3>
          <div className="d-flex my-3 flex-wrap">
              <div className="">
                  <div className="text-uppercase text-muted small">Percentage</div>
                  <h5 className="color-price-up">+0.61%</h5>
              </div>
              <div className="px-4">
                  <div className="text-uppercase text-muted small">Periods</div>
                  <h5>24 Hours</h5>
              </div>
          </div>

          <div className="col-xl-12" style={{ position: 'relative' }}>
              <div className="d-flex flex-wrap justify-content-between mb-1">
                  <div>Time:<span style={{color:'#4dca88'}}>{time} </span>   O:<span style={{color:'#4dca88'}}>{open} </span> H:<span style={{color:'#4dca88'}}>{hight}</span>  L:<span style={{color:'#4dca88'}}>{low} </span>C:<span style={{color:'#4dca88'}}>{close}</span></div>
              </div>
          </div>

          <div className="col-xl-7" style={{ position: 'relative' }}>
                <div
                  ref={chartContainerRef}
                  className="chart-container"
                  // style={{ height: "100%" }}
                />      


              <div className="resize-triggers">
                  <div className="expand-trigger">
                      <div style={{ width: '1px', height: '1px' }}>
                      
                      </div>
                  </div>
                  <div className="contract-trigger"></div>
              </div>
          </div>

    
          
          <div className="col-xl-5">
              <form>
                  <div className="row g-3 mb-3">
                      <div className="col-xl-12">
                          <label className="form-label">From</label>
                          <div className="input-group">
                              <input type="text" className="form-control" />
                              <button className="btn btn-outline-secondary" type="button">Max</button>
                              <button className="btn btn-outline-secondary dropdown-toggle" type="button" data-bs-toggle="dropdown" aria-expanded="false">USDT</button>
                              <ul className="dropdown-menu dropdown-menu-end">
                                  <li><a className="dropdown-item" href="#!">CYX</a></li>
                                  <li><a className="dropdown-item" href="#!">BTCC</a></li>
                                  <li><a className="dropdown-item" href="#!">ETHC</a></li>
                                  <li><a className="dropdown-item" href="#!">USDC</a></li>
                              </ul>
                          </div>
                      </div>
                      <div className="col-xl-12">
                          <label className="form-label">To</label>
                          <div className="input-group">
                              <input type="text" className="form-control" />
                              <button className="btn btn-outline-secondary dropdown-toggle" type="button" data-bs-toggle="dropdown" aria-expanded="false">CYX</button>
                              <ul className="dropdown-menu dropdown-menu-end">
                                  <li><a className="dropdown-item" href="#!">BTC</a></li>
                                  <li><a className="dropdown-item" href="#!">BTC</a></li>
                                  <li><a className="dropdown-item" href="#!">ETH</a></li>
                                  <li><a className="dropdown-item" href="#!">BUSD</a></li>
                                  <li><a className="dropdown-item" href="#!">XRP</a></li>
                                  <li><a className="dropdown-item" href="#!">DOT</a></li>
                                  <li><a className="dropdown-item" href="#!">FIL</a></li>
                                  <li><a className="dropdown-item" href="#!">DOGE</a></li>
                              </ul>
                          </div>
                      </div>
                      <div className="col-xl-12">
                          <div className="d-flex flex-wrap justify-content-between mb-1">
                              <div>Price</div>
                              <div className="text-muted"> 1 CYX = 0.091 USDT</div>
                          </div>
                          <div className="d-flex flex-wrap justify-content-between mb-1">
                              <div>Inverse Price</div>
                              <div className="text-muted"> 1 CYX = 1.11 USDT</div>
                          </div>
                          <div className="d-flex flex-wrap justify-content-between">
                              <div>You will receive</div>
                              <div className="text-primary"> 1.11 CYX</div>
                          </div>
                      </div>
                  </div>
                  <button type="submit" className="btn flex-fill btn-light-warning py-2 fs-5 text-uppercase px-5">Place Order</button>
              </form>
          </div>
        </>
    )
}

export default OtcPortalMarket;