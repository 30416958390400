const initialState = {
    btccPrice:0,
    ethcPrice:0,
    cyxPrice:0,
    usdtPrice:0,
    usdcPrice:0
}

const marketReducer = (state = initialState, action) => {
    switch (action.type) {
        case 'BTCCPRICE': {
            return {
                ...state,
                btccPrice: action.payload
            }
        } 
        case 'ETHCPRICE': {
            return {
                ...state,
                ethcPrice: action.payload
            }
        }
        case 'CYXPRICE':{
            return{
                ...state,
                cyxPrice:action.payload
            }
        }
        case 'USDTPRICE':{
         
            return{
                ...state,
                usdtPrice:action.payload
            }
        }
        case 'USDCPRICE':{
            return{
                ...state,
                usdcPrice:action.payload
            }
        }
        default: {
            return state
        }
    }

}

export {marketReducer}