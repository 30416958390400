
import { NumericFormat } from 'react-number-format';

export const CryptoBalanceTable={
    columns:[
        {
            name: "Asset",
            selector: (row) => row.contractName,
            cell: row => <><img className="avatar rounded-circle mx-3" src={row.contractImage} alt="" /> <span><div style={{fontSize:'14px', fontWeight:'bold', color:'#dbd2d2'}}>{row.contractSymbol}</div><div style={{fontSize:'14px', color:'#807b7b', marginTop:'5px'}}>{row.contractName}</div></span></>,
            sortable: true, minWidth: "190px"
        },
        {
            name: "Total",
            selector: (row) => row.totalAmount,
            cell: row => <><span><div style={{fontSize:'14px', fontWeight:'bold'}}>{Number(row.totalAmount.replace(',','.')).format(6, 3, '.', ',') }</div><div style={{fontSize:'14px', fontWeight:'bold', color:'#7d7979'}}>${Number(row.totalBalance.replace(',','.')).format(2, 3, '.', ',') }</div></span></>,
            sortable: true, minWidth: "190px"
        },
        {
            name: "Price",
            selector: (row) => Number(row.contractPrice.replace(',','.')).format(2, 3, '.', ','),
            sortable: true
        },
        {
            name: "Available",
            selector: (row) => row.availableAmount,
            cell: row => <><span><div style={{fontSize:'14px', fontWeight:'bold'}}>{Number(row.availableAmount.replace(',','.')).format(6, 3, '.', ',') }</div><div style={{fontSize:'14px', fontWeight:'bold', color:'#7d7979'}}>{Number(row.availableBalance.replace(',','.')).format(2, 3, '.', ',') }</div></span></>,
            sortable: true, minWidth: "200px"
        },
        {
            name: "Collateral",
            selector: (row) => row.collateralAmount,
            cell: row => <><span><div >{Number(row.collateralAmount.replace(',','.')).format(6, 3, '.', ',') }</div><div>{Number(row.collateralBalance.replace(',','.')).format(2, 3, '.', ',') }</div></span></>,
            sortable: true, minWidth: "200px"
        },
        {
            name: "Stake",
            selector: (row) => row.stakeAmount,
            cell: row => <><span><div >{Number(row.stakeAmount.replace(',','.')).format(6, 3, '.', ',') }</div><div>{Number(row.stakeBalance.replace(',','.')).format(2, 3, '.', ',') }</div></span></>,
            sortable: true, minWidth: "200px"
        },
        {
            name: "Locked",
            selector: (row) => row.lockedAmount,
            cell: row => <><span><div >{Number(row.lockedAmount.replace(',','.')).format(6, 3, '.', ',') }</div><div>{Number(row.lockedBalance.replace(',','.')).format(2, 3, '.', ',') }</div></span></>,
            sortable: true, minWidth: "200px"
        },
    ]
}