import React, { useEffect, useState } from 'react';

import Banner1 from '../../assets/images/slider-text-back1.png'
import Banner2 from '../../assets/images/slider-text-back2.png'
import Banner3 from '../../assets/images/slider-text-back3.png'
import Banner4 from '../../assets/images/slider-text-back4.png'

function DashBoardTopCard() {


    const [vipLevel, setVipLevel] = useState('');

    useEffect(() => {
        let AuthInformation = JSON.parse(sessionStorage.getItem('AuthInformation'));
        if (typeof AuthInformation !== 'undefined' && AuthInformation) {
            setVipLevel(AuthInformation.vipLevel);
        };
    }, [])

    return (
        <div className="card-body" >
            <div className="row g-3">
                <div className="col-xl-3 col-xxl-3" style={{marginTop:'29px'}}>
                    <select className="form-select" style={{ marginTop: '6px' }}>
                        <option value="1">Main Wallet</option>
                        {/* <option value="1">Wallet 1</option>
                        <option value="2">Nabi</option>
                        <option value="3">Kasa</option>
                        <option value="4">Yazılım</option>
                        <option value="5">Test Wallet</option> */}
                    </select>
                </div>
                <div className="col-xl-3 col-xxl-4" style={{marginTop:'29px'}}>
                    <div className="input-group" style={{ marginTop: '6px' }}>
                        <input type="text" className="form-control" placeholder="Wallet Name" onChange={() => { }} />
                        <button className="btn btn-primary text-uppercase" type="button">Add Wallet</button>
                    </div>
                </div>
                <div className="col-xl-5 col-sm-12" style={{ textAlign: 'center', alignItems: 'center', marginTop:'23px' }}>

                    {vipLevel == 0 &&
                        <img  style={{width:'250px'}} src={Banner1} />
                    }

                    {vipLevel == 1 &&
                        <img style={{width:'250px'}} src={Banner2} />
                    }

                    {vipLevel == 2 &&
                        <img style={{width:'250px'}} src={Banner3} />
                    }

                    {vipLevel == 3 &&
                        <img style={{width:'250px'}} src={Banner4} />
                    }

                </div>
            </div>
        </div>
    )
}

export default DashBoardTopCard;