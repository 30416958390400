import React, { forwardRef, useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { onWithdrawBalance, onMinWithdraw, onLimit24Hours, onNetworkFee, onNetwork, onSymbol, onContract } from '../../Redux/Actions/withdrawAction';
import { Dropdown, Nav, Tab, Button, TabContainer } from 'react-bootstrap';
import { ContractApi } from "../../Apis/ContractApi";
import { AccountApi } from "../../Apis/AccountApi";
import { TransactionApi } from "../../Apis/TransactionApi";
import { Alert } from "react-bootstrap";
import { Input, Popover, Radio, Modal } from "antd";
import tokenList from "./tokenList.json";
import CurrencyInput from 'react-currency-input-field';
import TransferImg from '../../assets/images/transfer.gif'

function WithdrawCrypto(props) {

    const navigate = useNavigate();
    const baseUrl = process.env.PUBLIC_URL;

    const optNetwork = [
        { id: 0, text: 'Select Network' },
        { id: 1, text: 'CYX20 (Arrival time ≈ 1 - 5 minute)' },
        { id: 2, text: 'ERC20 (Arrival time ≈ 2 - 360 minute)' },
        { id: 3, text: 'BEP20 (Arrival time ≈ 2 - 360 minute)' },
        { id: 4, text: 'TRC20 (Arrival time ≈ 2 - 360 minute)' },
    ];

    const { symbol, contract, withdrawBalance, minWithdraw, limit24Hours, networkFee, network } = props.withdrawReducer;

    const [isOpen, setIsOpen] = useState(false);
    const [optionsCoin, setoptionsCoin] = useState([]);
    const [selectedCoin, setSelectedCoin] = useState('0');
    const [selectedNetwork, setSelectedNetwork] = useState(0);
    const [selectedAddress, setSelectedAddress] = useState('');
    const [selectedWitBalance, setSelectedWitBalance] = useState('');
    const [maxSelectedWitBalance, setMaxSelectedWitBalance] = useState('');
    const [selectedPassword, setSelectedPassword] = useState('');

    const [warningAlert, setWarningAlert] = useState(false);
    const [successAlert, setSuccessAlert] = useState(false);
    const [warningAlertMessage, setWarningAlertMessage] = useState('');

    const [buttonText, setbuttonText] = useState('Confirm');
    const [buttonDisabled, setbuttonDisabled] = useState(false);

    const [maxEnable, setMaxEnable] = useState(false);


    const [feeGasPrice, setFeeGasPrice] = useState(0);
    const [google2FAStatus, setGoogle2FAStatus] = useState('');
    const [google2FACode, setGoogle2FACode] = useState('');

    useEffect(() => {
        ContractApi.getAllContract()
            .then((response) => {

                var arr = [];
                arr.push({ 'address': '0', 'symbol': 'Select Coin' });
                for (const item of response.data) {
                    //console.log(item.address);
                    //console.log(item.symbol)
                    arr.push({ 'address': item.address, 'symbol': item.symbol });
                };

                setoptionsCoin(arr);

                if (typeof symbol === 'undefined' && symbol && symbol === '') {
                    props.onSymbol(arr[0].symbol)
                    getBalance(arr[0].address);
                }
                if (selectedCoin == '0')
                    props.onWithdrawBalance(Number(0));

            });
    }, [props.withdrawReducer])

    const getBalance = coin => {
        console.log(coin);
        if (coin == '0') {
            props.onMinWithdraw(0.001);
            props.onNetworkFee('0.145 - 15');
            props.onLimit24Hours(10000000);
            props.onWithdrawBalance(Number(0));
            props.onContract(coin)
            setSelectedCoin(coin);
            setSelectedNetwork(0);
            setMaxSelectedWitBalance(0);
            props.onNetwork(0);

            return
        }

        console.log('Balace Coin :' + coin)
        let AuthInformation = JSON.parse(sessionStorage.getItem('AuthInformation'));
        //console.log(AuthInformation);
        if (AuthInformation === null || AuthInformation === undefined || AuthInformation === "") {
            console.log("Login için Yönlendirme Yapılıyor...");
            sessionStorage.removeItem('AuthToken');
            sessionStorage.removeItem('AuthInformation');
            sessionStorage.removeItem('reduxState');
            navigate(baseUrl + '/sign-in')
        }

        props.onContract(coin)
        setSelectedCoin(coin);

        AccountApi.getTokenBalance({ 'address': AuthInformation?.wallets[0].cyx20WalletAddress, 'contract': coin })
            .then((response) => {
                //console.log(response);
                if (Number(response?.code) != -1) {
                    var balance = response?.data.availableAmount.replace(',', '.');
                    if (Number(balance) < 0)
                        balance = 0;
                    props.onWithdrawBalance(Number(balance));
                    setMaxSelectedWitBalance(Number(balance))
                }
                else
                    props.onWithdrawBalance(null);
                //Default
                props.onMinWithdraw(0.001);
                props.onNetworkFee('0.145 - 15');
                props.onLimit24Hours(10000000);
            });
    }

    const coinHandleChange = event => {
        setMaxEnable(false);
        setSelectedWitBalance(0);
        props.onSymbol(event.target.selectedOptions[0].label);
        getBalance(event.target.value);
    };

    const networkHandleChange = event => {

        setSelectedNetwork(event.target.value);
        props.onNetwork(event.target.value);

        let feeGas = 0;
        //let witPrice = selectedWitBalance;
        feeGas = selectedWitBalance / 1000;
        // if(event.target.value==1)
        //     feeGas = feeGas * 3;
        // else if(event.target.value==2)
        // feeGas = feeGas * 91.97;
        // else if(event.target.value==3)
        // feeGas = feeGas * 71.5;
        // else if(event.target.value==4)
        // feeGas = feeGas * 82.4;

        if (maxEnable) {
            maxAmount();
        } else {
            feeCalculator();
        }



    };

    const addresssHandleChange = event => {
        //getBalance(selectedCoin);
        //console.log(selectedCoin);
        setSelectedAddress(event.target.value);
    };

    const passwordHandleChange = event => {
        setSelectedPassword(event.target.value);
    };

    const google2FAHandleChange = event => {
        setGoogle2FACode(event.target.value);
    };

    function handleDecimalsOnValue(value) {
        const regex = /([0-9]*[\.]{0,1}[0-9]{0,8})/s;
        return value.match(regex)[0];
    }


    useEffect(() => {

        console.log("Max Durumu : " + maxEnable);
        feeCalculator(selectedWitBalance);

    }, [selectedWitBalance, selectedNetwork, maxEnable])

    function test() {
        console.log("Test Çalıştı");
    }

    function feeCalculator() {
        setMaxEnable(false);
        console.log(selectedWitBalance);
        let feeGas = 0;
        if (selectedWitBalance != '' || selectedWitBalance != null || selectedWitBalance != undefined || selectedWitBalance > 0.0001) {
            console.log("Gelen Bakiye :" + selectedWitBalance);
            feeGas = selectedWitBalance / 1000;

            if (selectedNetwork == 1)
                feeGas = feeGas * 3;
            else if (selectedNetwork == 2)
                feeGas = feeGas * 100;
            else if (selectedNetwork == 3)
                feeGas = feeGas * 4;
            else if (selectedNetwork == 4)
                feeGas = feeGas * 7;

            if (feeGas > 0 && feeGas < 0.0001)
                feeGas = 0.0001;

            feeGas = feeGas.toFixed(6);
            console.log("Hesaplanan Fee : " + feeGas);
            setFeeGasPrice(feeGas);
        }
    }

    function witBalanceHandleChange(_amount) {
        console.log("Burası Text Change");
        console.log(maxEnable);
        setMaxEnable(false);
        let value = _amount;
        //const value = handleDecimalsOnValue(event.target.value);
        if (value == '' || value == null || value == undefined)
            setSelectedWitBalance("");
        //console.log("Gelen Value :" + value);
        setSelectedWitBalance(value);


    };

    const maxEnableTrue = event =>
    {
        setMaxEnable(true);
        maxAmount();
    }

    const maxAmount = event => {
        console.log("Gelen Fiyat : " + maxSelectedWitBalance);
        if (maxSelectedWitBalance != '' || maxSelectedWitBalance != null || maxSelectedWitBalance != undefined || maxSelectedWitBalance > 0.0001) {
            let feeGas = 0;
            let witPrice = 0;
            feeGas = maxSelectedWitBalance / 1000;

            console.log("ilk fee :"  + feeGas);

            if (selectedNetwork == 1)
                feeGas = feeGas * 3;
            else if (selectedNetwork == 2)
                feeGas = feeGas * 100;
            else if (selectedNetwork == 3)
                feeGas = feeGas * 4;
            else if (selectedNetwork == 4)
                feeGas = feeGas * 7;

            if (feeGas > 0 && feeGas < 0.0001)
                feeGas = 0.0001;

            feeGas = feeGas.toFixed(6);

            console.log("Hesaplanan Fee :" + feeGas);

            witPrice = withdrawBalance - feeGas

            //getBalance(witPrice);
            setSelectedWitBalance(witPrice.toFixed(6));
            setFeeGasPrice(feeGas);
        }
    }

    const withdrawComplateHandle = event => {
        console.log('selectedWitBalance' + selectedWitBalance);
        var total = Number(selectedWitBalance.replace(',', '.')) + Number(feeGasPrice.replace(',', '.'));
        console.log('Total : ' + total);
        setbuttonDisabled(true);
        setbuttonText('Process');
        if (selectedAddress == '' || selectedAddress == null || selectedAddress == 'undefined') {
            setWarningAlertMessage('Address cannot be empty');
            setWarningAlert(true);
            setbuttonDisabled(false);
            setbuttonText('Confirm');
        }
        else if (selectedPassword == '' || selectedPassword == null || selectedPassword == 'undefined') {
            setWarningAlertMessage('Password cannot be empty');
            setWarningAlert(true);
            setbuttonDisabled(false);
            setbuttonText('Confirm');
        }
        else if (Number(selectedNetwork) == 0) {
            setWarningAlertMessage('Please Select Network');
            setWarningAlert(true);
            setbuttonDisabled(false);
            setbuttonText('Confirm');
        }
        else if (selectedCoin == "0") {
            setWarningAlertMessage('Please Coin');
            setWarningAlert(true);
            setbuttonDisabled(false);
            setbuttonText('Confirm');
        }
        else if (Number(selectedWitBalance) < Number(minWithdraw)) {
            setWarningAlertMessage('Amount cannot be less than Minimum value');
            setWarningAlert(true);
            setbuttonDisabled(false);
            setbuttonText('Confirm');
        }
        else if (Number(total) > Number(withdrawBalance)) {
            setWarningAlertMessage('Insufficient Balance');
            setWarningAlert(true);
            setbuttonDisabled(false);
            setbuttonText('Confirm');
        }
        else {
            let AuthInformation = JSON.parse(sessionStorage.getItem('AuthInformation'));
            var transfer = {
                senderAdress: AuthInformation?.wallets[0].cyx20WalletAddress,
                receiverAdress: selectedAddress,
                amount: selectedWitBalance,
                NetworkId: network,
                Contract: contract,
                CoinName: symbol,
                Email: AuthInformation.email,
                password: selectedPassword,
                gasFee: feeGasPrice,
                Google2FaCode : google2FACode
            }

            setGoogle2FAStatus(false);
            TransactionApi.transfer(transfer)
                .then((response) => {
                    console.log(response);
                    if (response.status == true) {
                        
                        setSuccessAlert(true);
                        setbuttonDisabled(false);
                        setbuttonText('Confirm');

                        setSelectedAddress('');
                        setSelectedWitBalance(0);
                        props.onWithdrawBalance(0);
                        setFeeGasPrice(0);
                        props.onMinWithdraw(0.001);
                        props.onNetworkFee('0.145 - 15');
                        props.onLimit24Hours(10000000);
                        props.onWithdrawBalance(Number(0));
                        props.onContract('0')
                        setSelectedCoin('0');
                        setSelectedNetwork(0);
                        props.onNetwork(0);
                        setMaxEnable(false);
                    }
                    else 
                    {
                        if(response.code==-2)
                        {
                            setbuttonDisabled(false);
                            setbuttonText('Confirm');
                            setGoogle2FAStatus(true);
                        }
                        else if(response.code==-1)
                        {
                            //setGoogle2FAStatus(true);
                            setbuttonDisabled(false);
                            setWarningAlertMessage(response.message);
                            setWarningAlert(true);
                            setGoogle2FACode('');
                        }
                        else
                        {

                            setWarningAlertMessage(response.message);
                            setWarningAlert(true);
                            setbuttonDisabled(false);
                            setbuttonText('Confirm');
                        }
                        
                    }
                })
                .catch(err => {
                    //setWarningAlert(true); 
                    setbuttonDisabled(false);
                    setbuttonText('Confirm');
                    // if(err.response.status==401)
                    // {
                    //     sessionStorage.removeItem('AuthToken');
                    //     sessionStorage.removeItem('AuthInformation');
                    //     sessionStorage.removeItem('reduxState');
                    //     navigate(baseUrl + '/sign-in')

                    // }
                    // //console.log(err.response.data.message)
                    // //setWarningAlertMessage(err.response.message);

                });
        }
    };

    function modiftyToken(i) {

        //setIsOpen(false);

    }

    function openModal(asset) {

    }


    return (

        <>
            <Modal
                open={isOpen}
                footer={null}
                onCancel={() => setIsOpen(false)}
                title="Select a token"
            >
                <div className='modalContent'>
                    {tokenList?.map((e, i) => {
                        return (
                            <div
                                className='tokenChoice'
                                key={i}
                                onClick={() => modiftyToken(i)}>
                                <img src={e.img} alt={e.symbol} className="tokenLogo" />
                                <div className="tokenChoiceNames">
                                    <div className='tokenName'>{e.name}</div>
                                    <div className='tokenTicker'>{e.symbol}</div>
                                </div>
                                <div style={{ marginLeft: "auto", display: "flex", paddingRight: "10px" }}>$10.00</div>
                            </div>
                        );
                    })}
                </div>
            </Modal>

            <div className="col-xl-6 col-xxl-5">
                <div className="card">

                    <Tab.Container defaultActiveKey="first">
                        <div className="card-header py-3 d-flex justify-content-between bg-transparent border-bottom align-items-center flex-wrap">
                            <h6 className="mb-0 fw-bold">Withdraw</h6>
                            <Nav className="nav nav-tabs tab-body-header rounded d-inline-flex mt-2 mt-md-0" role="tablist">
                                <Nav.Item className="nav-item"><Nav.Link className="nav-link" eventKey="first" data-bs-toggle="tab" href="#P2P" role="tab">Cyrpto</Nav.Link></Nav.Item>
                                <Nav.Item className="nav-item"><Nav.Link className="nav-link" eventKey="second" data-bs-toggle="tab" href="#Margin" role="tab">Cash</Nav.Link></Nav.Item>
                            </Nav>
                        </div>
                        <div className="card-body">
                            <Tab.Content className="tab-content">
                                <Tab.Pane className="tab-pane fade show" id="Spot" eventKey="first">

                                {/* {(() => {
                                    if (someCase) {
                                    return (
                                        <div>someCase</div>
                                    )
                                    } else if (otherCase) {
                                    return (
                                        <div>otherCase</div>
                                    )
                                    } else {
                                    return (
                                        <div>catch all</div>
                                    )
                                    }
                                })()} */}

                                {buttonDisabled && !google2FAStatus  && 
                                    <div className="row g-3 mb-3">
                                         <div className="col-sm-12">
                                         <div style={{fontSize:'25px', textAlign:'center'}}>Please wait, the transaction is in progress</div>
                                         <div style={{textAlign:'center'}}>
                                            <img src={TransferImg} style={{width:'40%', height:'40%'}}  alt="profile" />
                                         </div>
                                         </div>
                                        
                                    </div>
                                }

                                {!buttonDisabled && !google2FAStatus  && 

                                    <div className="row g-3 mb-3">
                                        <div className="col-sm-12">
                                            <label className="form-label" > <strong style={{ color: '#ffb01a', fontSize: '12px' }}> Balance {(withdrawBalance).toString()}  </strong>  <Button style={{ marginLeft: '10px', fontSize: '10px' }} onClick={() => maxEnableTrue()}>  Max </Button> </label>

                                            <div className="input-group">

                                                <CurrencyInput
                                                    className="form-control"
                                                    style={{ width: "50%" }}
                                                    decimalSeparator="."
                                                    groupSeparator=","
                                                    id="input-withdraw"
                                                    name="withdraw"
                                                    placeholder="Amount"
                                                    value={selectedWitBalance}
                                                    decimalsLimit={6}
                                                    onValueChange={(value, name, values) => witBalanceHandleChange(value) }
                                                    onChange={feeCalculator}
                                                    inputMode="text"
                                                    maxLength="12"

                                                />

                                                {/* <input type="text" name="withdraw" className="form-control" style={{width : "60%"}} pattern="^[0-9]*[.,]?[0-9]*$" value={selectedWitBalance} onChange={ witBalanceHandleChange} /> */}
                                                <select value={selectedCoin} onChange={coinHandleChange} className="form-select" style={{ minWidth: '135px', backgroundColor: '#0095ec', color: 'white' }}>
                                                    {optionsCoin.map(coin => (
                                                        <option key={coin.address} value={coin.address}>
                                                            {coin.symbol}
                                                        </option>
                                                    ))}
                                                </select>
                                            </div>
                                        </div>
                                        <div className="col-sm-12">
                                            <label className="form-label"> <strong style={{ color: '#ffb01a', fontSize: '12px' }}> Minumum Withdrawal : {minWithdraw} {symbol} </strong> </label>
                                            <input type="text" name="address" placeholder='Withdraw Address' autoComplete='off' className="form-control" value={selectedAddress} onChange={addresssHandleChange} />
                                        </div>
                                        <div className="col-sm-12">
                                            {/* <label className="form-label"> <strong style={{ color: '#ffb01a', fontSize: '12px' }}> 24h remaining limit : ${(limit24Hours).toFixed(2).replace(/(\d)(?=(\d{3})+(?!\d))/g, "$1,")} {symbol} </strong>  </label> */}
                                            <select value={selectedNetwork} onChange={networkHandleChange} style={{ backgroundColor: '#0095ec', color: 'white' }} className="form-select">
                                                {optNetwork.map(network => (
                                                    <option key={network.id} value={network.id}>
                                                        {network.text}
                                                    </option>
                                                ))}
                                            </select>
                                            {/* <select className="form-select" aria-label="Default select example">
                                            <option value="1">CYX20 (Arrival time ≈ 1 minite)</option>
                                            <option value="2">ERC20 (Arrival time ≈ 2 - 120 mins)</option>
                                            <option value="3">BEP20 (Arrival time ≈ 2 - 120 mins)</option>
                                        </select> */}
                                        </div>
                                        <div className="col-sm-12">
                                            <label className="form-label">Password</label>
                                            <input type="password" name="address" className="form-control" value={selectedPassword} onChange={passwordHandleChange} />
                                        </div>
                                        {/* <div className="col-sm-12">
                                        <div className="d-flex justify-content-between flex-wrap">
                                            <div>
                                            <div className="truncated">Minimum withdrawal</div>
                                                <div className="text-muted  truncated">{minWithdraw} {symbol} </div>
                                            </div>
                                            <div>
                                                <div className="truncated">{symbol} balance </div>
                                                <div className="text-muted truncated"> {(withdrawBalance).toFixed(2).replace(/(\d)(?=(\d{3})+(?!\d))/g, "$1,")}</div>
                                            </div>
                                        </div>
                                    </div> */}
                                        <div className="col-sm-12">
                                            <div className="d-flex justify-content-between flex-wrap">
                                                <div>
                                                    <div className="truncated">Network Fee</div>
                                                    {/* <div className="text-muted truncated"> {networkFee} {symbol}</div> */}
                                                    <div className="text-muted truncated"> {feeGasPrice} {symbol}</div>

                                                </div>
                                                <div>
                                                    <button type="submit" disabled={buttonDisabled} onClick={withdrawComplateHandle} className="btn btn-primary  py-2 fs-5 text-uppercase px-5">{buttonText}</button>
                                                </div>
                                            </div>
                                        </div>

                                        {/* <div className="mb-12" style={{textAlign:'center', alignItems:'center', marginTop:'30px'}}>
                                       
                                    </div> */}
                                        <div className='col-sm-12'>
                                            <Alert variant='warning' show={warningAlert} className="alert-dismissible"  >
                                                <strong>{warningAlertMessage}</strong>
                                                <button type="button" className="btn-close" data-dismiss="alert" aria-label="Close" onClick={() => { setWarningAlert(false) }}></button>
                                            </Alert>
                                            <Alert variant='success' show={successAlert} className="alert-dismissible"  >
                                                <strong>Transfer Success</strong>
                                                <button type="button" className="btn-close" data-dismiss="alert" aria-label="Close" onClick={() => { setSuccessAlert(false) }}></button>
                                            </Alert>
                                        </div>
                                    </div>
                                }

                                {google2FAStatus  && 

                                <div className="row g-3 mb-3">
                                    <div className="col-sm-12">
                                        <label className="form-label">2FA Code</label>
                                        <input type="text" name="address" className="form-control" value={google2FACode} onChange={google2FAHandleChange} />
                                    </div>
                                    <div className="col-sm-12">
                                        <div className="d-flex justify-content-between flex-wrap">
                                            <div>
                                                <button type="submit" disabled={buttonDisabled} onClick={withdrawComplateHandle} className="btn btn-primary  py-2 fs-5 text-uppercase px-5">{buttonText}</button>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                }
                                
                                </Tab.Pane>
                                <Tab.Pane className="tab-pane fade" id="P2P" eventKey="second">

                                    <div className="row g-3 mb-3">
                                        <Dropdown className="col-sm-12">
                                            <label className="form-label">Select Currency</label>
                                            <div className="input-group">
                                                <input type="text" className="form-control" onChange={() => { }} />
                                                <Dropdown.Toggle className="btn btn-outline-secondary dropdown-toggle" type="button" data-bs-toggle="dropdown" aria-expanded="false">GBP</Dropdown.Toggle>
                                                <Dropdown.Menu className="dropdown-menu dropdown-menu-end">
                                                    <li><Dropdown.Item className="dropdown-item" href="#">GBP</Dropdown.Item></li>
                                                    <li><Dropdown.Item className="dropdown-item" href="#">USD</Dropdown.Item></li>
                                                    <li><Dropdown.Item className="dropdown-item" href="#">EUR</Dropdown.Item></li>
                                                    <li><Dropdown.Item className="dropdown-item" href="#">JPY</Dropdown.Item></li>
                                                </Dropdown.Menu>
                                            </div>
                                        </Dropdown>
                                        <div className="col-sm-12">
                                            <label className="form-label">Swift</label>
                                            <input type="text" className="form-control" onChange={() => { }} />
                                        </div>
                                        <div className="col-sm-12">
                                            <label className="form-label">Iban</label>
                                            <input type="text" className="form-control" onChange={() => { }} />
                                        </div>
                                        <div className="col-sm-12">
                                            <label className="form-label">Recipient</label>
                                            <input type="text" className="form-control" onChange={() => { }} />
                                        </div>
                                        <div className="col-sm-12">
                                            <div className="d-flex justify-content-between flex-wrap">
                                                <div>
                                                    <div className="truncated">balance</div>
                                                    <div className="text-muted truncated"> 0</div>
                                                </div>
                                                <div>
                                                    <div className="truncated">Minimum withdrawal</div>
                                                    <div className="text-muted  truncated"> 1 </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="col-sm-12">
                                            <div className="d-flex justify-content-between flex-wrap">
                                                <div>
                                                    <div className="truncated">fee</div>
                                                    <div className="text-muted truncated"> 1 ~ 5 CYX</div>
                                                </div>
                                                {/* <div>
                                                    <div className="truncated">24h remaining limit</div>
                                                    <div className="text-muted  truncated"> 1.000.000 </div>
                                                </div> */}
                                            </div>
                                        </div>
                                        <div className="mb-3">
                                            <button type="submit" className="btn btn-primary  py-2 fs-5 text-uppercase px-5">Confirm</button>
                                        </div>
                                    </div>
                                </Tab.Pane>
                            </Tab.Content>
                        </div>
                    </Tab.Container>
                </div>
            </div>
        </>
    )
}

const mapStateToProps = ({ withdrawReducer }) => ({
    withdrawReducer
})

export default connect(mapStateToProps, {
    onWithdrawBalance, onMinWithdraw, onLimit24Hours, onNetworkFee, onNetwork, onSymbol, onContract
})(WithdrawCrypto);