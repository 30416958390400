import { api } from "./configs/axiosConfigs"
import { defineCancelApiObject } from "./configs/axiosUtils"

export const OraclePriceApi = {
    swap: async function (data, cancel = false) {
        let AuthInformation = JSON.parse(sessionStorage.getItem('AuthInformation'));
        const response = await api.request({
            url: `OraclePrice/Swap`,
            method: "POST",
            data: JSON.stringify(data), 
                headers:{
                    'Content-Type': 'application/json; charset=utf-8',
                    'Authorization' : `Bearer ${AuthInformation?.token}`
                },
            signal: cancel ? cancelApiObject[this.swap.name].handleRequestCancellation().signal : undefined,
        })

        return response.data
    },
}

// defining the cancel API object for ProductAPI
const cancelApiObject = defineCancelApiObject(OraclePriceApi)