import React , { useState}  from "react";
import { Nav, Tab } from "react-bootstrap";
import { Link, useNavigate,Outlet } from "react-router-dom";
import AuthRight from "./AuthRight";
import { AccountApi } from "../../Apis/AccountApi";
import menu from '../Data/Menu/Menu.json';
import { Alert } from "react-bootstrap";
import { connect  } from 'react-redux';

function Signin(props) {

    const navigate = useNavigate();
    const [menuData, setMenuData] = useState([...menu.menu]);
    var baseUrl = process.env.PUBLIC_URL

    const initialState = {
        email: "",
        password: "",
        code:""
      };

      const [login, setLogin] = useState(initialState);
      const [warningAlert, setWarningAlert] = useState(false);
      const [successAlert, setSuccessAlert] = useState(false);
      const [disabledForm, setDisabledForm] = useState(false);
      const [logInButtonName, setlogInButtonName] = useState('LOG IN');
      const [errorMessage, setErrorMessage] = useState('User Not Found');
      const [google2FA, setGoogle2FA] = useState(false);
    
      function handleSubmit(e) {
        e.preventDefault();
        if (!login.email) {
          return;
        }

        if (!login.password) {
            return;
        }
        setDisabledForm(true);
        setlogInButtonName('Please Wait')
        AccountApi.login(login)
        .then((response) => {
            console.log(response);
            if(response.status)
            {
                if(response.code == - 2)
                {
                    setDisabledForm(false);
                    setlogInButtonName('LOG IN')
                    setGoogle2FA(true);
                }
                else if(response.code== -1)
                {
                    console.log('Eksi 1 e Geldim')
                    setErrorMessage(response.message)
                    setWarningAlert(true); 
                    setDisabledForm(false);
                    setlogInButtonName('LOG IN')
                }
                else
                {
                    setSuccessAlert(true);
                    setDisabledForm(false);
                    sessionStorage.setItem('AuthToken', response.data.token)
                    sessionStorage.setItem('AuthInformation', JSON.stringify(response.data));
                    //console.log(props);
                    navigate(baseUrl + '/')
                    setMenuData([...menu.menu]);
                }
            }
            else
            {
                setErrorMessage(response.message)
                setWarningAlert(true); 
                setDisabledForm(false);
                setLogin(initialState);
                setlogInButtonName('LOG IN')
            }
          })
          .catch((error) => {
            //console.log(error);
            setWarningAlert(true); 
            setDisabledForm(false);
            setLogin(initialState);
            setlogInButtonName('LOG IN')
            //console.log("the error has occured: " + JSON.stringify(error));
          });
        
      };
    
      function handleInputChange(event) {
        const { name, value } = event.target;
        setLogin({ ...login, [name]: value });
      };

    return (<>
        <div className="body d-flex p-0 p-xl-5">
            <div className="container">
                <div className="row g-3">
                    <div className="col-lg-6 d-flex justify-content-center align-items-center auth-h100">
                        <div className="d-flex flex-column">
                            <h1>Account Login</h1>
                            <span className="text-muted">Welcome back! Log In with your Email, Phone number or QR code</span>
                            <Tab.Container defaultActiveKey="first">
                                <Nav className="nav nav-pills mt-4" role="tablist" defaultActiveKey="first">
                                    <Nav.Item className="nav-item"><Nav.Link className="nav-link" eventKey="first">Email</Nav.Link></Nav.Item>
                                    {/* <Nav.Item className="nav-item"><Nav.Link className="nav-link" eventKey="second">Mobile</Nav.Link></Nav.Item> */}
                                </Nav>
                                <Tab.Content className="tab-content mt-4 mb-3" >
                                    <Tab.Pane className="tab-pane fade " id="Email" eventKey="first">
                                        <div className="card">
                                            <div className="card-body p-4">
                                                <form onSubmit={handleSubmit} autoComplete="off">
                                                <fieldset disabled={disabledForm}>
                                                {!google2FA &&
                                                    <div>
                                                        <div className="mb-3">
                                                        <label className="form-label fs-6">Email address</label>
                                                        {/* autoComplete="username" */}
                                                        <input type="email" className="form-control disabled" value={login.email} name="email" onChange={handleInputChange} />
                                                        </div>
                                                        <div className="mb-3">
                                                            <label className="form-label fs-6">Password</label>
                                                            {/* autoComplete="current-password"  */}
                                                            <input type="password" className="form-control" value={login.password} name="password" onChange={handleInputChange}/>
                                                        </div>
                                                    </div>
                                                    
                                                    }

                                                    {google2FA &&

                                                        <div className="mb-3">
                                                        <label className="form-label fs-6">2FA Code</label>
                                                        {/* autoComplete="current-password"  */}
                                                        <input type="code" className="form-control" value={login.code} name="code" onChange={handleInputChange}/>
                                                        </div>
                                                    
                                                    }

                                                    <button type="submit" content="Submit" aria-disabled="true" className="btn btn-primary text-uppercase py-2 fs-5 w-100 mt-2">{logInButtonName}</button>
                                                </fieldset>
                                                   
                                                </form>

                                                <div className="mb-3">
                                                    <label className="form-label fs-6"> </label>
                                                    <Alert variant='warning' show={warningAlert} className="alert-dismissible"  >
                                                        <strong>{errorMessage} </strong>
                                                        <button type="button" className="btn-close" data-dismiss="alert" aria-label="Close" onClick={() => { setWarningAlert(false) }}></button>
                                                    </Alert>
                                                </div>


                                                <div className="mb-3">
                                                    <label className="form-label fs-6"> </label>
                                                    <Alert variant='success' show={successAlert} className="alert-dismissible"  >
                                                        <strong>Login Success</strong> 
                                                        <button type="button" className="btn-close" data-dismiss="alert" aria-label="Close" onClick={() => { setSuccessAlert(false) }}></button>
                                                    </Alert>
                                                </div>


                                            </div>
                                        </div>
                                    </Tab.Pane>
                                    {/* <Tab.Pane className="tab-pane fade" id="Mobile" eventKey="second">
                                        <div className="card">
                                            <div className="card-body p-4">
                                                <form>
                                                    <label className="form-label fs-6">Mobile</label>
                                                    <Dropdown as='a' className="input-group mb-3">
                                                        <Dropdown.Toggle className="btn btn-outline-secondary dropdown-toggle" type="button" data-bs-toggle="dropdown" aria-expanded="false">+91 India</Dropdown.Toggle>
                                                        <Dropdown.Menu className="dropdown-menu">
                                                            <li><Dropdown.Item className="dropdown-item" href="#!">+376 Andora</Dropdown.Item></li>
                                                            <li><Dropdown.Item className="dropdown-item" href="#!">+61 Australia</Dropdown.Item></li>
                                                            <li><Dropdown.Item className="dropdown-item" href="#!">+55 Brazil</Dropdown.Item></li>
                                                        </Dropdown.Menu>
                                                        <input type="text" className="form-control" />
                                                    </Dropdown>
                                                    <div className="mb-3">
                                                        <label className="form-label fs-6">Password</label>
                                                        <input type="password" className="form-control" />
                                                    </div>
                                                    <button type="submit" className="btn btn-primary text-uppercase py-2 fs-5 w-100 mt-2">log in</button>
                                                </form>
                                            </div>
                                        </div>
                                    </Tab.Pane> */}
                                </Tab.Content>
                            </Tab.Container>
                            <Link to={process.env.PUBLIC_URL + "/forgot-password"} title="#" className="text-primary text-decoration-underline">Forgot password?</Link>
                            <Link to={process.env.PUBLIC_URL + "/sign-up"} title="#" className="text-primary text-decoration-underline">Register now</Link>
                        </div>
                    </div>
                    <AuthRight />
                </div>
            </div>
            <Outlet />
        </div>
    </>
    )
}

const mapStateToProps = ({  }) => ({
    
  })
  
export default connect(mapStateToProps, {
    
})(Signin);