import React from "react";
import {Tab,Nav} from 'react-bootstrap';
import DataTable from 'react-data-table-component';
import { FunData, OpenOrderData, OrderHistoryData, TradeHistoryData } from "../../Data/ExchangeData/OrderbookData";

function MarginStatus(){
    return(
        <div className="card">
            <div className="card-header py-3 d-flex justify-content-between bg-transparent border-bottom-0">
                <h6 className="mb-0 fw-bold ">Margin Status</h6>
            </div>
            <div className="card-body">
                <Tab.Container defaultActiveKey='first'>
                    <Nav className="nav nav-tabs tab-body-header rounded d-inline-flex mb-3" role="tablist">
                        <Nav.Item className="nav-item"><Nav.Link className="nav-link " eventKey='first' data-bs-toggle="tab" href="#OpenOrder" role="tab" aria-selected="true">Open Order(7)</Nav.Link></Nav.Item>
                        <Nav.Item className="nav-item"><Nav.Link className="nav-link" eventKey='second' data-bs-toggle="tab" href="#OrderHistory" role="tab" aria-selected="false">Order History</Nav.Link></Nav.Item>
                        <Nav.Item className="nav-item"><Nav.Link className="nav-link" eventKey='third' data-bs-toggle="tab" href="#TradeHistory" role="tab" aria-selected="false">Trade History</Nav.Link></Nav.Item>
                        <Nav.Item className="nav-item"><Nav.Link className="nav-link" eventKey='fourth' data-bs-toggle="tab" href="#Funds" role="tab" aria-selected="false">Funds</Nav.Link></Nav.Item>
                    </Nav>
                    <Tab.Content className="tab-content" >
                        <Tab.Pane className="tab-pane fade " id="OpenOrder" eventKey='first'>
                            <div className="row">
                                <div className="col-sm-12 col-md-6 mb-2 " style={{ display: 'flex',alignItems: 'center' }}>
                                    <div id="myDataTable_filter" className="dataTables_filter">
                                        <label className='d-flex align-items-center'>Search:<div className='mx-1'><input type="search" className="form-control" /></div></label>
                                    </div>
                                </div>
                            </div>
                            <DataTable
                                title={OpenOrderData.title}
                                columns={OpenOrderData.columns}
                                data={OpenOrderData.rows}
                                defaultSortField="title"
                                selectableRows={false}
                                highlightOnHover={true}
                            />
                        </Tab.Pane>
                        <Tab.Pane className="tab-pane fade" id="OrderHistory" eventKey='second'>
                            <div className="row">
                                <div className="col-sm-12 col-md-6 mb-2 " style={{ display: 'flex',alignItems: 'center' }}>
                                    <div id="myDataTable_filter" className="dataTables_filter">
                                        <label className='d-flex align-items-center'>Search:<div className='mx-1'><input type="search" className="form-control" /></div></label>
                                    </div>
                                </div>
                            </div>
                            <DataTable
                                title={OrderHistoryData.title}
                                columns={OrderHistoryData.columns}
                                data={OrderHistoryData.rows}
                                defaultSortField="title"
                                selectableRows={false}
                                highlightOnHover={true}
                            />
                        </Tab.Pane>
                        <Tab.Pane className="tab-pane fade" id="TradeHistory" eventKey='third'>
                            <div className="row">
                                <div className="col-sm-12 col-md-6 mb-2 " style={{ display: 'flex',alignItems: 'center' }}>
                                    <div id="myDataTable_filter" className="dataTables_filter">
                                        <label className='d-flex align-items-center'>Search:<div className='mx-1'><input type="search" className="form-control" /></div></label>
                                    </div>
                                </div>
                            </div>
                            <DataTable
                                title={TradeHistoryData.title}
                                columns={TradeHistoryData.columns}
                                data={TradeHistoryData.rows}
                                defaultSortField="title"
                                selectableRows={false}
                                highlightOnHover={true}
                            />
                        </Tab.Pane>
                        <Tab.Pane className="tab-pane fade" id="Funds" eventKey='fourth'>
                            <div className="row">
                                <div className="col-sm-12 col-md-6 mb-2 " style={{ display: 'flex',alignItems: 'center' }}>
                                    <div id="myDataTable_filter" className="dataTables_filter">
                                        <label className='d-flex align-items-center'>Search:<div className='mx-1'><input type="search" className="form-control" /></div></label>
                                    </div>
                                </div>
                            </div>
                            <DataTable
                                title={FunData.title}
                                columns={FunData.columns}
                                data={FunData.rows}
                                defaultSortField="title"
                                selectableRows={false}
                                highlightOnHover={true}
                            />
                        </Tab.Pane>
                    </Tab.Content>
                </Tab.Container>
            </div>
        </div>
    )
}

export default MarginStatus;