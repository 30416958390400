import React, {useState} from "react";
import { Link, useParams } from "react-router-dom";
import AuthRight from "./AuthRight";
import { Alert } from "react-bootstrap";
import { AccountApi } from "../../Apis/AccountApi";

function Verification(props) {

    const initialState = {
        email: "",
        code: ""
      };


    const [confirm, setConfirm] = useState(initialState);
    const [warningAlert, setWarningAlert] = useState(false);
    const [successAlert, setSuccessAlert] = useState(false);
    const [disabledForm, setDisabledForm] = useState(false);
    const [buttonName, setButtonName] = useState('Verify my Email');
    const [errorMessage, setErrorMessage] = useState('Invalid Verification Code');
    const { id, mail } = useParams();
    //console.log(id);
    //console.log(mail);

   
    if(confirm.code=='')
    {
        confirm.code = id;
        confirm.email = mail;
        setConfirm({ ...confirm, "code": id });
        setConfirm({ ...confirm, "email": mail });
    }
    

    function handleInputChange(event) {
        const { name, value } = event.target;
        setConfirm({ ...confirm, [name]: value });
      };

    function handleSubmit(e) {
        e.preventDefault();
        if (!confirm.code) {
          return;
        }

        if (!confirm.email) {
            return;
        }

        setDisabledForm(true);
        setButtonName('Please Wait')
        AccountApi.emailConfirm({'email':confirm.email, 'code':confirm.code})
        .then((response) => {
            console.log(response);
            if(response.status)
            {
                setSuccessAlert(true);
                setDisabledForm(false);
            }
            else
            {
                setErrorMessage(response.message)
                setWarningAlert(true); 
                setDisabledForm(false);
                setConfirm(initialState);
                setButtonName('Verify my Email')
            }
          })
          .catch((error) => {
            //console.log(error);
            setWarningAlert(true); 
            setDisabledForm(false);
            setConfirm(initialState);
            setButtonName('LOG IN')
            //console.log("the error has occured: " + JSON.stringify(error));
          });
    }

    return (
        <>
            <div className="body d-flex p-0 p-xl-5">
                <div className="container">
                    <div className="row g-3">
                        <div className="col-lg-6 d-flex justify-content-center align-items-center auth-h100">
                            <div className="d-flex flex-column">
                                <h1>Verification</h1>
                                <span className="text-muted">We sent a verification code to your email or phone. <br />Enter the code from the field below.</span>
                                <div className="card mt-4 mb-3" style={{ maxWidth: '30rem' }} >
                                    <div className="card-body p-4">
                                        <form  onSubmit={handleSubmit} className="row g-1">
                                        <fieldset disabled={disabledForm}>
                                            <div className="col">
                                                <div className="mb-12">
                                                    <input type="text" value={confirm.code} onChange={handleInputChange} name='code' className="form-control form-control-lg text-center" placeholder="-" />
                                                </div>

                                                <div className="mb-12">
                                                    <input type="text" value={confirm.email} onChange={handleInputChange} name='mail'  className="form-control form-control-lg text-center" placeholder="-" />
                                                </div>

                                            </div>
                                         
                                            <div className="col-12 text-center mt-4">
                                                <button type="submit"  onClick={() => { setSuccessAlert(false) }} className="btn btn-primary text-uppercase py-2 fs-5 w-100">{buttonName}</button>
                                            </div>

                                            </fieldset>
                                        </form>
                                        <div className="mb-3">
                                                    <label className="form-label fs-6"> </label>
                                                    <Alert variant='warning' show={warningAlert} className="alert-dismissible"  >
                                                        <strong>{errorMessage} </strong>
                                                        <button type="button" className="btn-close" data-dismiss="alert" aria-label="Close" onClick={() => { setWarningAlert(false) }}></button>
                                                    </Alert>
                                                </div>


                                                <div className="mb-3">
                                                    <label className="form-label fs-6"> </label>
                                                    <Alert variant='success' show={successAlert} className="alert-dismissible"  >
                                                        <strong>Verification Success</strong> 
                                                        <button type="button" className="btn-close" data-dismiss="alert" aria-label="Close" onClick={() => { setSuccessAlert(false) }}></button>
                                                    </Alert>
                                                </div>
                                    </div>
                                </div>
                                <Link to={process.env.PUBLIC_URL+"/sign-in"} title="#" className="text-primary text-decoration-underline">Log In</Link>
                            </div>
                        </div>

                        <AuthRight/>
                    </div>
                </div>
            </div>
        </>
    )
}

export default Verification;