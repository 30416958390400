import React from "react";
import { Route, Routes } from 'react-router-dom';
import { connect } from 'react-redux';
import { onModalOpen } from '../Redux/Actions/mainActions';
import AuthHeader from "../Component/Comman/AuthHeader";
import Signin from "../Component/Auth/SignIn";
import NewModal from "../Component/Comman/NewModal";
import Signup from "../Component/Auth/Signup";
import ForgotPassword from "../Component/Auth/ForgotPassword";
import Verification from "../Component/Auth/Verification";
import ErrorPage from "../Component/Auth/ErrorPage";

function AuthIndex(props) {
    const { modalopen, darkMode, highcontrast, rtlmode } = props.mainReducer;
    const baseUrl =process.env.PUBLIC_URL;
    window.document.children[0].setAttribute('data-theme', 'dark');
    return (
        <div className="main p-2 py-3 p-xl-5">
            <AuthHeader
                onModalOpen={(val) => { props.onModalOpen(true) }}
            />
            <NewModal
                show={modalopen}
                highcontrast={highcontrast}
                darkMode={darkMode}
                onHide={(val) => { props.onModalOpen(false) }}
                onChangeDarkMode={() => { props.onChangeDarkMode(darkMode === 'dark' ? 'light' : 'dark') }}
                onChangeHighcontrast={() => { props.onChangeHighcontrast(highcontrast === 'high-contrast' ? 'light' : 'high-contrast') }}
                OnchangeRTLmode={() => { props.OnchangeRTLmode(rtlmode === 'rtl_mode' ? true : false) }}
            />
            <Routes>
                <Route exact path={baseUrl+'/sign-in'} element={<Signin />} />
                <Route exact path={baseUrl+"/sign-up"} element={<Signup />} />
                <Route exact path={baseUrl+"/forgot-password"} element={<ForgotPassword />} />
                <Route exact path={baseUrl+"/verification/:id/:mail"} element={<Verification />} />
                <Route exact path={baseUrl+"/404page"} element={<ErrorPage />} />
                <Route path="*" element={<p>Path not resolved</p>} />

            </Routes>
        </div>
    )
}

const mapStateToProps = ({ mainReducer  }) => ({
    mainReducer
})

export default connect(mapStateToProps, {
    onModalOpen
})(AuthIndex); 