import "./Swap.css";
import Swap from "../../Component/Swap/Swap";
import { SpeechToTextComponent } from '../../Component/Dashboard/SpeechToTextComponent';


function SwapPage() {
  return (

    <div className="container-xxl">
      <div className="row  marjinSifir">
          <div className="col-md-auto">
          <div className="card-body">
                <Swap />
              </div>
             
            </div>
      
        </div>

        <div className="row">
                <SpeechToTextComponent></SpeechToTextComponent>
        </div>
    </div>
    
  )
}

export default SwapPage;