export const onWithdrawBalance = (val) => (dispatch) => {
    dispatch({
        type: 'WITHDRAWBALANCE',
        payload: val
    })
}

export const onMinWithdraw = (val) => (dispatch) => {
    dispatch({
        type: 'MINWITHDRAW',
        payload: val
    })
}

export const onLimit24Hours = (val) => (dispatch) => {
    dispatch({
        type: 'LIMIT24HOURS',
        payload: val
    })
}


export const onNetworkFee = (val) => (dispatch) => {
    dispatch({
        type: 'NETWORKFEE',
        payload: val
    })
}

export const onNetwork = (val) => (dispatch) => {
    dispatch({
        type: 'NETWORK',
        payload: val
    })
}

export const onSymbol = (val) => (dispatch) => {
    dispatch({
        type: 'SELECTSYMBOL',
        payload: val
    })
}

export const onContract = (val) => (dispatch) => {
    dispatch({
        type: 'SELECTCONTRACT',
        payload: val
    })
}