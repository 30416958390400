

export const EcommerceData=[
    {
        text:'IN',
        name:'Inventory',
        time:'15/06/2021',
        message:'The point of using Lorem Ipsum'
    },
    {
        text:'OD',
        name:'order',
        time:'13/06/2021',
        message:'If you are going to use a passage'
    },
    {
        text:'PD',
        name:'Product',
        time:'12/06/2021',
        message:'The point of using Lorem Ipsum'
    },

]
