export const onBTCCPrice = (val) => (dispatch) => {
    
    dispatch({
        type: 'BTCCPRICE',
        payload: val
    })
}

export const onETHCPrice = (val) => (dispatch) => {
    dispatch({
        type: 'ETHCPRICE',
        payload: val
    })
}

export const onCYXPrice = (val) => (dispatch) => {
    dispatch({
        type: 'CYXPRICE',
        payload: val
    })
}

export const onUSDTPrice = (val) => (dispatch) => {
    //console.log(val);
    dispatch({
        type: 'USDTPRICE',
        payload: val
    })
}


export const onUSDCPrice = (val) => (dispatch) => {
    dispatch({
        type: 'USDCPRICE',
        payload: val
    })
}