import React,{ useEffect,useState,useCallback } from "react";
import { TransactionData } from "../Data/WalletData/TransactionData";
import DataTable from 'react-data-table-component';
import { TransactionApi } from "../../Apis/TransactionApi";
import PlaceholderLoading from 'react-placeholder-loading'

function Transaction() {

    const veri = [];

    const customStyles = {
        table: {
            style: {
                color:'var(--text-color)',
                backgroundColor: '#00000000 !important',
            },
        },
        rows: {
            style: {
                backgroundColor: '#00000000 !important',
                outline: '1px solid var(--card-color)',
                color: 'var(--text-color)',
            },
            highlightOnHoverStyle: {
                backgroundColor: '#00000000 !important',
                color: 'var(--text-color)',
                outline: '1px solid var(--card-color)',
            },
        },
        headCells:{
            style: {
                backgroundColor: 'var(--body-color)',
                color: 'var(--text-color)'
            }
        },
        noData: {
            style: {
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
                color: 'var(--text-color)',
                backgroundColor: '#00000000 !important',
            },
        },
    };
    
    const  TnxData = { 
        title:'History',
        columns:[
            {
                name: "Hash",
                selector: (row) => <><a href={row.LocalHashLink} target="_blank"><span >{row.Hash}</span></a></>,
                sortable: true,  minWidth: "190px"
            },
            {
                name: "Date",
                selector: (row) => row.Date,
                sortable: true,  maxWidth: "170px"
            },
            {
                name: "Method",
                selector: (row) => row.Method,
                sortable: true,  maxWidth: "70px"
                
            },
            {
                name: "From",
                selector: (row) => row.From,
                sortable: true
            },
            {
                name: "Type",
                selector: (row) => row.Type,
                cell:(row)=><><span className={`${row.Type=='In'?'color-price-up':'color-price-down'}`}>{row.Type}</span></>,
                sortable: true,  maxWidth: "70px"
            },
            {
                name: "To",
                selector: (row) => row.To,
                sortable: true
            },
            {
                name: "Symbol",
                selector: (row) => row.Symbol,
                sortable: true, maxWidth: "70px"
            },
            {
                name: "Amount",
                selector: (row) => row.Amount,
                sortable: true,  maxWidth: "150px"
            },
            {
                name: "Network",
                selector: (row) => row.Network,
                cell:(row)=><><a href={row.HashRemote} target="_blank"><span >{row.Network}</span></a></>,
                sortable: true, maxWidth: "130px"
            },
            {
                name: "Status",
                selector: (row) => row.Status,
                sortable: true ,  maxWidth: "70px"
            },

        ],
        rows : []

    }

    

    const initialState = {
        address: "",
        pageNumber: 1,
        pageSize: 10
    };

    const [tableData, setTableData] = useState(null);
    const [pageCountData, setPageCountData] = useState([]);
    const [pageNumber, setPageNumber] = useState(1);
    const [maxPageNumber, setMaxPageNumber] = useState(1);

    //use effect
    useEffect(() => {
        
        //
        //setPageNumber(1);
        pageChangeApiCall();
        
    },[])

    //Api calc
    function pageChangeApiCall()
    {
        let AuthInformation = JSON.parse(sessionStorage.getItem('AuthInformation'));
        if (typeof AuthInformation !== 'undefined' && AuthInformation) {
            
            initialState.address=AuthInformation?.wallets[0].cyx20WalletAddress;
            
            TransactionApi.getTransaction(initialState)
            .then((response) => 
            {
                console.log(response);
                if(response.data!=null)
                {
                    TnxData.rows =[];
                    for (const item of response?.data?.transactions) {
                        //console.log(item);
                        TnxData.rows.push({'Date': item.date, 'Hash': item.hashLocal, 'LocalHashLink': item.localHashLink , 'Method': item.method, 'Symbol': item.symbol, 'Network': item.network , 'Status': item.status, 'Amount': item.amount, 'From': item.from, 'Type': item.type, 'To': item.to , 'HashRemote': item.hashRemote});
                    };

                    var list = []
                    var page = Number(response?.data?.transactionCount) / 10;
                    //console.log(page);
                    var count = Math.ceil(page);
                    //console.log(count);
                    for (var i=1;i<count+1;i++) {
                        list.push({'id':i,'text':i});
                    }
                    setMaxPageNumber(count);
                    setPageCountData(list);
                    setTableData(TnxData);
                }
               
            })
            .catch((err) =>
            {
                console.log(err);
            });
        };
    }

    //change page
    const changePage = useCallback(async (id) => {
        //console.log(id);
        initialState.pageNumber=id;
        setPageNumber(id);
        pageChangeApiCall();
    }, [initialState])

    //previous page
    const previous = useCallback(async () => {
        //console.log(maxPageNumber);
        if(pageNumber>1)
        {
            var number = pageNumber -1;
            initialState.pageNumber=number;
            setPageNumber(number);
            pageChangeApiCall();
        }
        
    },[initialState])

    //next page 
    const next = useCallback(async () => {
        //console.log(maxPageNumber);
        if(pageNumber<maxPageNumber)
        {
            var number = pageNumber + 1;
            initialState.pageNumber=number;
            setPageNumber(number);
            pageChangeApiCall();
        }
        
    },[initialState])


    return (
        <div className="col-xl-12">
            <div className="card">
                <div className="card-header py-3 d-flex justify-content-between">
                    <h6 className="mb-0 fw-bold">Recent Transactions</h6>
                </div>
                <div className="card-body">
                    <div id="ordertabthree_wrapper" className="dataTables_wrapper dt-bootstrap5 no-footer">
                        {/* <div className="" style={{ display: 'flex', flexWrap: 'wrap' }} >
                            <div className="col-sm-12 col-md-6 mb-2 " style={{ display: 'flex', alignItems: 'center'  }}>
                                <div id="myDataTable_filter" className="dataTables_filter">
                                    <label className='d-flex align-items-center'>Search:<div className='mx-1'><input type="search" className="form-control" /></div></label>
                                </div>
                            </div>
                        </div> */}
                        <div className="row">
                            <div className="col-sm-12">

                           

                            {(() => {
                                if (tableData==null) {
                                return (
                                  
                                  <div>Loading Data</div>
                                 
                    
                                
                                )
                                } else {
                                return (
                                    <DataTable
                                    title={tableData.title}
                                    columns={tableData.columns}
                                    data={tableData.rows}
                                    defaultSortField="title"
                                    selectableRows={false}
                                    highlightOnHover
                                    customStyles={customStyles}
                                />
                                )
                                }
                            })()}
                               
                            </div>
                        </div>
                        <div className="row mt-3 ">
                            {/* <div className="col-sm-12 col-md-5" style={{ float: 'left' }}>
                                <div className="dataTables_info" id="myDataTable_info" role="status" aria-live="polite">Showing 1 to 7 of 7 entries</div>
                            </div> */}
                            <div className="col-sm-12 col-md-12 " >
                                <div className="dataTables_paginate paging_simple_numbers table-responsive" id="myDataTable_paginate">
                                    <ul className="pagination">
                                        <li className="paginate_button page-item previous" id="myDataTable_previous"><a href="#" onClick={() => previous()}  className="page-link">Previous</a></li>
                                        {pageCountData.map(item => (
                                            <li key={item.id} className={ item.id == pageNumber ? "paginate_button page-item active" : "paginate_button page-item" }><a href="#"  onClick={() => changePage(item.id)} key={item.id} className="page-link">{item.text}</a></li>
                                        ))}
                                        {/* <li className="paginate_button page-item active"><a href="#!" className="page-link">1</a></li> */}
                                        <li className="paginate_button page-item next" id="myDataTable_next"><a href="#" onClick={() => next()} className="page-link">Next</a></li>
                                    </ul>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}
export default Transaction;