import React , { useState , useEffect  }from "react";
import { Nav, Tab, Button } from "react-bootstrap";
import QRCode from "react-qr-code";

function Deposit() {

    const [cyxAddress, setCyxAddress] = useState('');
    const [bep20Address, setBep20Address] = useState('');
    const [erc20Address, setErc20Address] = useState('');
    const [trc20Address, setTrc20Address] = useState('');


    const [copySuccess, setCopySuccess] = useState('');

    useEffect(() => {
        //console.log("Deposit Component Start");
        let AuthInformation = JSON.parse(sessionStorage.getItem('AuthInformation'));
        if (typeof AuthInformation !== 'undefined' && AuthInformation) {
            //console.log(JSON.stringify(AuthInformation))
            setCyxAddress(AuthInformation?.wallets[0].cyx20WalletAddress);
            setBep20Address(AuthInformation?.wallets[0].bep20WalletAddress);
            setErc20Address(AuthInformation?.wallets[0].erc20WalletAddress);
            setTrc20Address(AuthInformation?.wallets[0].trc20WalletAddress);
            //console.log("Yeni Adres : "+ trc20Address);
        }
        
    }, [cyxAddress,bep20Address,erc20Address,trc20Address])

    const copyToClipBoard = async copyMe => {
        //alert('ok');
        try {
          await navigator.clipboard.writeText(copyMe);
          setCopySuccess('Copied!');
        } catch (err) {
          setCopySuccess('Failed to copy!');
        }
      };
    

    return (
        <div className="col-xl-6 col-xxl-7">
            <div className="card">
                <Tab.Container defaultActiveKey='first'>
                    <div className="card-header py-3 d-flex justify-content-between bg-transparent border-bottom align-items-center flex-wrap">
                        <h6 className="mb-0 fw-bold">Deposit</h6>
                        <Nav className="nav nav-tabs tab-body-header rounded d-inline-flex" role="tablist">
                            <Nav.Item className="nav-item"><Nav.Link className="nav-link " eventKey='first' >CYX20</Nav.Link></Nav.Item>
                            <Nav.Item className="nav-item"><Nav.Link className="nav-link " eventKey='second' >BEP20</Nav.Link></Nav.Item>
                            <Nav.Item className="nav-item"><Nav.Link className="nav-link " eventKey='third' >ERC20</Nav.Link></Nav.Item>
                            <Nav.Item className="nav-item"><Nav.Link className="nav-link " eventKey='four' >TRC20</Nav.Link></Nav.Item>
                            <Nav.Item className="nav-item"><Nav.Link className="nav-link" eventKey='five'>Cash</Nav.Link></Nav.Item>
                        </Nav>
                    </div>
                    <div className="card-body">
                        <Tab.Content className="tab-content">
                            <Tab.Pane className="tab-pane" eventKey='first' id="cyx">
                                {/* <form> */}
                                    <div className="mb-12">
                                        <label className="form-label">Wallet Address</label>
                                        <div className="row row-cols-1 row-cols-md-1 row-cols-lg-1 row-cols-xl-1">
                                            <div className="col">
                                           <strong  className="wallet-min-text">{cyxAddress} </strong>    
                                            <a href="#">
                                            <i className="icofont-copy-invert" style={{marginTop:'10px', marginLeft:'10px', color:'#0095ec', fontSize:'19px', cursor:'grab'}} onClick={() => copyToClipBoard(cyxAddress)}></i>
                                            </a>
                                           
                                            {/* <Button style={{marginTop:'10px', marginLeft:'10px'}} onClick={() => copyToClipBoard(cyxAddress)}>
                                              Copy
                                            </Button> */}
                                                
                                            </div>
                                        </div>
                                    </div>
                                 
                                    <div className="mb-3">
                                        <div className="d-flex flex-wrap align-items-center">
                                     
                                            <QRCode
                                            size={256}
                                            style={{ height: "auto", maxWidth: "200px", width: "200px", margin:"20px" }}
                                            value={cyxAddress}
                                            viewBox={`0 0 256 256`} />
                                      
                                       
 
                                            <div className="d-flex flex-wrap px-lg-2">
                                                <div>
                                                    <div className="truncated">Expected Arrival</div>
                                                    <div className="text-muted truncated mb-1"> 3 network confirm</div>
                                                    <div className="truncated">Expected Unlock</div>
                                                    <div className="text-muted truncated"> 3 network confirm</div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    {/* <div className="mb-3">
                                        <button type="submit" className="btn flex-fill btn-light-warning py-2 fs-5 text-uppercase px-5">Confirm</button>
                                    </div>
                                </form> */}
                            </Tab.Pane>
                            <Tab.Pane className="tab-pane fade" eventKey='second' id="bep20">
                            {/* <form> */}
                                    <div className="mb-12">
                                        <label className="form-label">Wallet Address</label>
                                        <div className="row row-cols-1 row-cols-md-1 row-cols-lg-1 row-cols-xl-1">
                                            <div className="col">
                                            <strong  className="wallet-min-text">{bep20Address} </strong>    
                                           
                                           <a href="#">
                                            <i className="icofont-copy-invert" style={{marginTop:'10px', marginLeft:'10px', color:'#0095ec', fontSize:'19px', cursor:'grab'}} onClick={() => copyToClipBoard(bep20Address)}></i>
                                            </a>
                                           {/* <Button style={{marginTop:'10px', marginLeft:'10px'}} onClick={() => copyToClipBoard(bep20Address)}>
                                              Copy
                                            </Button> */}
                                            </div>
                                        </div>
                                    </div>
                                 
                                    <div className="mb-3">
                                        <div className="d-flex flex-wrap align-items-center">
                                            <QRCode
                                            size={256}
                                            style={{ height: "auto", maxWidth: "200px", width: "200px", margin:"20px" }}
                                            value={bep20Address}
                                            viewBox={`0 0 256 256`} />
                                            <div className="d-flex flex-wrap px-lg-2">
                                                <div>
                                                    <div className="truncated">Expected Arrival</div>
                                                    <div className="text-muted truncated mb-1"> 3 network confirm</div>
                                                    <div className="truncated">Expected Unlock</div>
                                                    <div className="text-muted truncated"> 3 network confirm</div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    {/* <div className="mb-3">
                                        <button type="submit" className="btn flex-fill btn-light-warning py-2 fs-5 text-uppercase px-5">Confirm</button>
                                    </div>
                                </form> */}
                            </Tab.Pane>
                            <Tab.Pane className="tab-pane fade" eventKey='third' id="erc20">
                            {/* <form> */}
                                    <div className="mb-12">
                                        <label className="form-label">Wallet Address</label>
                                        <div className="row row-cols-1 row-cols-md-1 row-cols-lg-1 row-cols-xl-1">
                                            <div className="col">
                                            <strong  className="wallet-min-text"> {erc20Address} </strong>    
                                          
                                           <a href="#">
                                            <i className="icofont-copy-invert" style={{marginTop:'10px', marginLeft:'10px', color:'#0095ec', fontSize:'19px', cursor:'grab'}} onClick={() => copyToClipBoard(erc20Address)}></i>
                                            </a>
                                           {/* <Button style={{marginTop:'10px', marginLeft:'10px'}} onClick={() => copyToClipBoard(erc20Address)}>
                                              Copy
                                            </Button> */}
                                            </div>
                                        </div>
                                    </div>
                                 
                                    <div className="mb-3">
                                        <div className="d-flex flex-wrap align-items-center">
                                            <QRCode
                                            size={256}
                                            style={{ height: "auto", maxWidth: "200px", width: "200px", margin:"20px" }}
                                            value={erc20Address}
                                            viewBox={`0 0 256 256`} />
                                            <div className="d-flex flex-wrap px-lg-2">
                                                <div>
                                                    <div className="truncated">Expected Arrival</div>
                                                    <div className="text-muted truncated mb-1"> 3 network confirm</div>
                                                    <div className="truncated">Expected Unlock</div>
                                                    <div className="text-muted truncated"> 3 network confirm</div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    {/* <div className="mb-3">
                                        <button type="submit" className="btn flex-fill btn-light-warning py-2 fs-5 text-uppercase px-5">Confirm</button>
                                    </div>
                                </form> */}
                            </Tab.Pane>
                            <Tab.Pane className="tab-pane fade" eventKey='four' id="trc20">
                            {/* <form> */}
                                    <div className="mb-12">
                                        <label className="form-label">Wallet Address</label>
                                        <div className="row row-cols-1 row-cols-md-1 row-cols-lg-1 row-cols-xl-1">
                                            <div className="col">
                                            <strong  className="wallet-min-text">  {trc20Address}</strong>
                                          

                                           <a href="#">
                                            <i className="icofont-copy-invert" style={{marginTop:'10px', marginLeft:'10px', color:'#0095ec', fontSize:'19px', cursor:'grab'}} onClick={() => copyToClipBoard(trc20Address)}></i>
                                            </a>
                                            
                                           {/* <Button style={{marginTop:'10px', marginLeft:'10px', color:'#0095ec', fontSize:'19px', cursor:'grab'}} onClick={() => copyToClipBoard(trc20Address)}>
                                              Copy
                                            </Button> */}
                                            </div>
                                        </div>
                                    </div>
                                 
                                    <div className="mb-3">
                                        <div className="d-flex flex-wrap align-items-center">
                                            <QRCode
                                            size={256}
                                            style={{ height: "auto", maxWidth: "200px", width: "200px", margin:"20px" }}
                                            value={trc20Address}
                                            viewBox={`0 0 256 256`} />
                                            <div className="d-flex flex-wrap px-lg-2">
                                                <div>
                                                    <div className="truncated">Expected Arrival</div>
                                                    <div className="text-muted truncated mb-1"> 3 network confirm</div>
                                                    <div className="truncated">Expected Unlock</div>
                                                    <div className="text-muted truncated"> 3 network confirm</div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    {/* <div className="mb-3">
                                        <button type="submit" className="btn flex-fill btn-light-warning py-2 fs-5 text-uppercase px-5">Confirm</button>
                                    </div>
                                </form> */}
                            </Tab.Pane>

                        </Tab.Content>
                    </div>
                </Tab.Container>
            </div>
        </div>
    )
}

export default Deposit;
