import React, { Component ,useEffect, useState  } from "react"
import { Dropdown, Nav, Tab } from "react-bootstrap";
import { Link } from "react-router-dom";
import AuthRight from "./AuthRight";
import { AccountApi } from "../../Apis/AccountApi";
import { Alert } from "react-bootstrap";

function Signup(props) {

    const initialState = {
        email: "",
        password: "",
        referralID: 0
      };
    
      const [register, setRegister] = useState(initialState);
      const [disabledForm, setDisabledForm] = useState(false);
      const [registerButtonName, setregisterButtonName] = useState('Create Account');
      const [warningAlert, setWarningAlert] = useState(false);
      const [warningAlertMessage, setWarningAlertMessage] = useState('');
      const [successAlert, setSuccessAlert] = useState(false);
      const [recovery, setrecovery] = useState(false);


    const [passwordType, setPasswordType] = useState("password");
    const [passwordInput, setPasswordInput] = useState("");

    function togglePassword(e) { 
        e.preventDefault();
        if(passwordType==="password")
        {
         setPasswordType("text")
         return;
        }
        setPasswordType("password")
      }
    
      function handleSubmit(e) {
        e.preventDefault();
        if (!register.email) {
          return;
        }
        setDisabledForm(true);
        setregisterButtonName('Please Wait');
        AccountApi.register(register)
        .then((response) => {
            if(response?.status)
            {
                setrecovery(response?.data?.passphrase)
                setSuccessAlert(true);
            }
            else
            {
                if(response != undefined)
                {
                    //console.log('geldim');
                    setWarningAlertMessage(response?.message);
                    setWarningAlert(true); 
                }
            }
            setDisabledForm(false);
            setregisterButtonName('Create Account');
            setRegister(initialState);
          })
          .catch((error) => {
            setWarningAlertMessage('System Error Please Try Later!');
            setWarningAlert(true); 
            setregisterButtonName('Create Account');
            setDisabledForm(false);
            setRegister(initialState);
          });
      };
    
      function handleInputChange(event) {
        if(event.target.name == "password")
            setPasswordInput(event.target.value);

        const { name, value } = event.target;
        setRegister({ ...register, [name]: value });
      };


    return (
        <>
            <div className="body d-flex p-0 p-xl-5">
                <div className="container">
                    <div className="row g-3">
                        <div className="col-lg-6 d-flex justify-content-center align-items-center auth-h100">
                            <div className="d-flex flex-column">
                                <h1>Create Your Account</h1>
                                <span className="text-muted">Register with your email</span>
                                <Tab.Container defaultActiveKey="first">
                                    <Nav className="nav nav-pills mt-4" role="tablist">
                                        <Nav.Item className="nav-item"><Nav.Link className="nav-link" eventKey="first">Email</Nav.Link></Nav.Item>
                                        {/* <Nav.Item className="nav-item"><Nav.Link className="nav-link" eventKey="second">Mobile</Nav.Link></Nav.Item> */}
                                    </Nav>
                                    <Tab.Content className="tab-content mt-4 mb-3">
                                        <Tab.Pane className="tab-pane fade" id="Email" eventKey="first">
                                            <div className="card">
                                                <div className="card-body p-4">
                                                    <form onSubmit={handleSubmit} autoComplete="off">
                                                    <fieldset disabled={disabledForm}>
                                                        <div className="mb-3">
                                                            <label className="form-label fs-6">Email address</label>
                                                            <input type="email" className="form-control" autoComplete="username"  value={register.email} name="email" onChange={handleInputChange}/>
                                                        </div>
                                                        <div className="mb-3">
                                                            <label className="form-label fs-5">Password</label>
                                                            <div style={{display:'flex'}}>
                                                            <div className="input-group-btn">
                                                            <input type={passwordType} className="form-control"  autoComplete="current-password" value={register.password} name="password" onChange={handleInputChange}/>
                                                            </div>
                                                                <button className="btn btn-outline-primary" type="button" onClick={togglePassword}>
                                                                { passwordType==="password"? <i className="bi bi-eye-slash"></i> :<i className="bi bi-eye"></i> }
                                                                </button>
                                                            </div>
                                                        </div>
                                                        <div className="mb-3">
                                                            <label className="form-label fs-6">Referral ID</label>
                                                            <input type="text" className="form-control" value={register.referralID} name="referralID"  onChange={handleInputChange} />
                                                        </div>
                                                        <button type="submit" content="Submit" className="btn btn-primary text-uppercase py-2 fs-5 w-100 mt-2">{registerButtonName}</button>
                                                    </fieldset>
                                                    </form>
                                                    <div className="mb-3">
                                                        <label className="form-label fs-6"> </label>
                                                        <Alert variant='warning' show={warningAlert} className="alert-dismissible"  >
                                                            <strong>{warningAlertMessage} </strong>
                                                            <button type="button" className="btn-close" data-dismiss="alert" aria-label="Close" onClick={() => { setWarningAlert(false) }}></button>
                                                        </Alert>
                                                    </div>

                                                    <div className="mb-3">
                                                        <label className="form-label fs-6"> </label>
                                                        <Alert variant='success' show={successAlert} className="alert-dismissible"  >
                                                            <strong>Registration Successful. Please Keep the Recovery key</strong> 
                                                            <div style={{padding:'10px'}}></div>
                                                            <strong>{recovery}</strong> 
                                                            <button type="button" className="btn-close" data-dismiss="alert" aria-label="Close" onClick={() => { setSuccessAlert(false) }}></button>
                                                        </Alert>
                                                    </div>

                                                </div>
                                            </div>
                                        </Tab.Pane>
                                        {/* <Tab.Pane className="tab-pane fade" id="Mobile" eventKey="second">
                                            <div className="card">
                                                <div className="card-body p-4">
                                                    <form>
                                                        <label className="form-label fs-6">Mobile</label>
                                                        <Dropdown className="input-group mb-3">
                                                            <Dropdown.Toggle className="btn btn-outline-secondary dropdown-toggle" type="button">+91 India</Dropdown.Toggle>
                                                            <Dropdown.Menu className="dropdown-menu">
                                                                <li><Dropdown.Item className="dropdown-item" href="#!">+376 Andora</Dropdown.Item></li>
                                                                <li><Dropdown.Item className="dropdown-item" href="#!">+61 Australia</Dropdown.Item></li>
                                                                <li><Dropdown.Item className="dropdown-item" href="#!">+55 Brazil</Dropdown.Item></li>
                                                            </Dropdown.Menu>
                                                            <input type="text" className="form-control" />
                                                        </Dropdown>
                                                        <div className="mb-3">
                                                            <label className="form-label fs-6">Password</label>
                                                            <input type="password" className="form-control" />
                                                        </div>
                                                        <div className="mb-3">
                                                            <label className="form-label fs-6">Referral ID</label>
                                                            <input type="text" className="form-control" />
                                                        </div>
                                                        <button type="submit" className="btn btn-primary text-uppercase py-2 fs-5 w-100 mt-2">Create Account</button>
                                                    </form>
                                                </div>
                                            </div>
                                        </Tab.Pane> */}
                                    </Tab.Content>
                                </Tab.Container>
                                <Link to={process.env.PUBLIC_URL+"/sign-in"} title="#">Already registered? <span className="text-secondary text-decoration-underline">Log In</span></Link>
                            </div>
                        </div>
                        <AuthRight />
                    </div>
                </div>
            </div>   
        </>
    )
}

export default Signup;