import React , {useState} from "react";
import { Dropdown, Nav, Tab } from "react-bootstrap";
import { Link } from "react-router-dom";
import AuthRight from "./AuthRight";
import { AccountApi } from "../../Apis/AccountApi";
import { Alert } from "react-bootstrap";

function ForgotPassword() {

    const initialState = {
        email: "",
        recoveryKey: "",
        password: "",
        repassword:""
      };

    const [forgotPassword, setForgotPassword] = useState(initialState);
    const [disabledForm, setDisabledForm] = useState(false);
    const [resetButtonName, setresetButtonName] = useState('Reset');
    const [warningAlert, setWarningAlert] = useState(false);
    const [successAlert, setSuccessAlert] = useState(false);

    const [warningAlertMessage, setWarningAlertMessage] = useState('');


    function handleSubmit(e) {
        e.preventDefault();
        if (!forgotPassword.email) {
            return;
        }else  if (!forgotPassword.email) {
            return;
        }else  if (!forgotPassword.password) {
            return;
        }else  if (!forgotPassword.repassword) {
            return;
        }
        setDisabledForm(true);
        setresetButtonName('Please Wait');
        AccountApi.forgotPassword(forgotPassword)
        .then((response) => {
            //console.log(JSON.stringify(response.message));
            if(response.status)
            {
                setSuccessAlert(true);
                setDisabledForm(false);
                setresetButtonName('Reset');
            }else
            {
                setWarningAlert(true); 
                setDisabledForm(false);
                setresetButtonName('Reset');
                
            }
            setForgotPassword(initialState);
          })
          .catch((error) => {
            if(error.response.data.message==='"Padding is invalid and cannot be removed."')
                setWarningAlertMessage('Recovery Words Are Incorrect');
            else
                setWarningAlertMessage(error.response.data.message);
            setWarningAlert(true); 
            setresetButtonName('Reset');
            setDisabledForm(false);
            setForgotPassword(initialState);
            //console.log("the error has occured: " + JSON.stringify(error.response.data.message));
          });
      };


    function handleInputChange(event) {
        const { name, value } = event.target;
        setForgotPassword({ ...forgotPassword, [name]: value });
    };

    return (
        <>
            <div className="body d-flex p-0 p-xl-5">
                <div className="container">
                    <div className="row g-3">
                        <div className="col-lg-6 d-flex justify-content-center align-items-center auth-h100">
                            <div className="d-flex flex-column">
                                <h1>Reset Your Password</h1>
                                <Tab.Container defaultActiveKey="first">
                                    <Nav className="nav nav-pills mt-4" role="tablist">
                                        <Nav.Item className="nav-item"><Nav.Link className="nav-link" eventKey="first" href="#!">Email</Nav.Link></Nav.Item>
                                        <Nav.Item className="nav-item"><Nav.Link className="nav-link" eventKey="second" href="#!">Mobile</Nav.Link></Nav.Item>
                                    </Nav>
                                    <Tab.Content className="tab-content mt-4 mb-3">
                                        <Tab.Pane className="tab-pane fade" id="Email" eventKey="first">
                                            <div className="card">
                                                <div className="card-body p-4">
                                                    <form onSubmit={handleSubmit} autoComplete="off">
                                                    <fieldset disabled={disabledForm}>
                                                    <div className="mb-3">
                                                            <label className="form-label fs-6">Email address</label>
                                                            <input type="email" className="form-control" value={forgotPassword.email} name="email" onChange={handleInputChange} />
                                                        </div>
                                                        <div className="mb-3">
                                                            <label className="form-label fs-6">12-Word Recovery Key</label>
                                                            <input type="text" className="form-control" value={forgotPassword.recoveryKey} name="recoveryKey" onChange={handleInputChange} />
                                                        </div>
                                                        <div className="mb-3">
                                                            <label className="form-label fs-6">Password</label>
                                                            <input type="password" className="form-control" value={forgotPassword.password} name="password" onChange={handleInputChange}/>
                                                        </div>
                                                        <div className="mb-3">
                                                            <label className="form-label fs-6">Re-Password</label>
                                                            <input type="password" className="form-control" value={forgotPassword.repassword} name="repassword" onChange={handleInputChange}/>
                                                        </div>
                                                        <button type="submit" content="Submit" aria-disabled="true" className="btn btn-primary text-uppercase py-2 fs-5 w-100 mt-2">{resetButtonName}</button>
                                                    </fieldset>
                                                    </form>
                                                    <div className="mb-3">
                                                        <label className="form-label fs-6"> </label>
                                                        <Alert variant='warning' show={warningAlert} className="alert-dismissible"  >
                                                            <strong>{warningAlertMessage}</strong>
                                                            <button type="button" className="btn-close" data-dismiss="alert" aria-label="Close" onClick={() => { setWarningAlert(false) }}></button>
                                                        </Alert>
                                                    </div>

                                                    <div className="mb-3">
                                                        <label className="form-label fs-6"> </label>
                                                        <Alert variant='success' show={successAlert} className="alert-dismissible"  >
                                                            <strong>Password Updated</strong> 
                                                            <button type="button" className="btn-close" data-dismiss="alert" aria-label="Close" onClick={() => { setSuccessAlert(false) }}></button>
                                                        </Alert>
                                                    </div>
                                                </div>
                                            </div>
                                        </Tab.Pane>
                                        <Tab.Pane className="tab-pane fade" id="Mobile" eventKey="second">
                                            <div className="card">
                                                <div className="card-body p-4">
                                                    <form>
                                                        <label className="form-label fs-6">Mobile</label>
                                                        <Dropdown className="input-group mb-3">
                                                            <Dropdown.Toggle className="btn btn-outline-secondary dropdown-toggle" type="button" data-bs-toggle="dropdown" aria-expanded="false">+91 India</Dropdown.Toggle>
                                                            <Dropdown.Menu className="dropdown-menu">
                                                                <li><Dropdown.Item className="dropdown-item">+376 Andora</Dropdown.Item></li>
                                                                <li><Dropdown.Item className="dropdown-item">+61 Australia</Dropdown.Item></li>
                                                                <li><Dropdown.Item className="dropdown-item">+55 Brazil</Dropdown.Item></li>
                                                            </Dropdown.Menu>
                                                            <input type="text" className="form-control" />
                                                        </Dropdown>
                                                        <Link to={process.env.PUBLIC_URL + "/verification"} className="btn btn-primary text-uppercase py-2 fs-5 w-100 mt-2">Next</Link>
                                                    </form>
                                                </div>
                                            </div>
                                        </Tab.Pane>
                                    </Tab.Content>
                                </Tab.Container>
                                <Link to={process.env.PUBLIC_URL+"/sign-in"} title="#"><span className="text-secondary text-decoration-underline">Log In</span></Link>
                            </div>
                        </div>
                        <AuthRight />
                    </div>
                </div>
            </div>
        </>
    )
}

export default ForgotPassword