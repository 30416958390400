import React from "react";

function P2PTopCard() {
    return (
        <div className="card-body" style={{backgroundColor:'#ffb01a'}}>
            <div className="row g-3">
                <div className="col-lg-3">
                    <select className="form-select">
                        <option value="1">Main Wallet</option>
                        {/* <option value="1">Wallet 1</option>
                        <option value="2">Nabi</option>
                        <option value="3">Kasa</option>
                        <option value="4">Yazılım</option>
                        <option value="5">Test Wallet</option> */}
                    </select>
                </div>
                <div className="col-lg-4">
                    <div className="input-group">
                        <input type="text" className="form-control" placeholder="Wallet Name" onChange={()=>{}}/>
                        <button className="btn btn-outline-secondary" type="button">Add Wallet</button>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default P2PTopCard;