import React from "react";
import QRcode from '../../assets/images/qr-code.png';

function AuthRight() {
    return (
        <div className="col-lg-6 d-none d-lg-flex justify-content-center align-items-center">
            <div className="qr-block text-center">
                <img src={QRcode} alt="#" className="img-fluid my-4" />
                <h4>Download Mobile Application</h4>
                <p>Download  <span className="text-primary">mobile app</span><br /> by Scanning QR code</p>
            </div>
        </div>
    )
}

export default AuthRight;