import React from "react";
import BreadcrumbTile from '../../Component/Ui Component/BreadcrumbTile'


function Breadcrumb () {
    return(
        <div className="container">
            <BreadcrumbTile />
        </div>
    )
  }

  
export default Breadcrumb;