import React, { useEffect, useState } from 'react';
import P1 from '../../assets/images/profile_av.svg'

import Elmas1 from '../../assets/images/slider-back1.png'
import Elmas2 from '../../assets/images/slider-back2.png'
import Elmas3 from '../../assets/images/slider-back3.png'
import Elmas4 from '../../assets/images/slider-back4.png'

import { maskEmail } from 'react-email-mask'
import 'react-email-mask/dist/index.css'

function UserDetailcard() {

    const [email, setEmail] = useState('');
    const [lastLogin, setLastLogin] = useState('');
    const [vipLevel, setVipLevel] = useState('');

    useEffect(() => {
        let AuthInformation = JSON.parse(sessionStorage.getItem('AuthInformation'));
        if (typeof AuthInformation !== 'undefined' && AuthInformation) {
            setEmail(maskEmail(AuthInformation.email));
            setLastLogin(AuthInformation.lastLogin);
            setVipLevel(AuthInformation.vipLevel);
        };
    }, [])

    return (
        <div className="row g-3 mb-3">
            <div className="col-lg-12">
                <div className="card">
                    <div className="card-body">
                        <div className="row g-1 align-items-center" style={{marginTop:'-3px'}}>
                            <div className="col-md-12 col-lg-12 col-xl-6">
                                <div className="d-flex">


                                    {vipLevel == 0 &&
                                        <img className="avatar rounded-circle" src={Elmas1} style={{ width: '50px', height: '50px', marginBottom: '-17px' }} alt="profile" />
                                    }

                                    {vipLevel == 1 &&
                                        <img className="avatar rounded-circle" src={Elmas2} style={{ width: '50px', height: '50px', marginBottom: '-17px' }} alt="profile" />
                                    }

                                    {vipLevel == 2 &&
                                        <img className="avatar rounded-circle" src={Elmas3} style={{ width: '50px', height: '50px', marginBottom: '-17px' }} alt="profile" />
                                    }

                                    {vipLevel == 3 &&
                                        <img className="avatar rounded-circle" src={Elmas4} style={{ width: '50px', height: '50px', marginBottom: '-17px' }} alt="profile" />
                                    }


                                    <div className="flex-fill ms-3">
                                        <p className="mb-0"><span className="font-weight-bold">{email} </span></p>
                                        <small className="">Last login {lastLogin}</small>
                                        {/* <span className="small text-muted d-flex align-items-center "><i style={{marginTop:'10px',marginLeft:'-7px'}} className="icofont-diamond px-1 fs-5 color-lightyellow "></i> VIP {vipLevel}</span> */}
                                    </div>
                                </div>
                            </div>
                            <div className="col-md-12 col-lg-12 col-xl-6">
                                <div className="d-flex flex-column">
                                    <span className="text-muted mb-1"></span>
                                    <span className="small text-muted flex-fill text-truncate"></span>
                                </div>
                            </div>
                            {/* <div className="col-md-6 col-lg-6 col-xl-2">
                                <div className="d-flex-inline">
                                    <span className="badge bg-careys-pink mb-1">User</span>
                                    <span className="small text-muted d-flex align-items-center"><i className="icofont-diamond px-1 fs-5 color-lightyellow "></i> VIP {vipLevel}</span>
                                </div>
                            </div> */}
                            {/* <div className="col-md-6 col-lg-6 col-xl-4">
                                <a href="#" title="invite" className="btn btn-primary text-dark mb-1">100 CYX commission:Invite friends now!</a>
                                <span></span>
                            </div> */}
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default UserDetailcard;