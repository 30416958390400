import { api } from "./configs/axiosConfigs"
import { defineCancelApiObject } from "./configs/axiosUtils"

export const ContractApi = {
    getAllContract: async function (cancel = false) {
        const response = await api.request({
            url: `Contract/GetAll`,
            method: "GET",
            signal: cancel ? cancelApiObject[this.getAllContract.name].handleRequestCancellation().signal : undefined,
        })
        return response.data
    },
}

// defining the cancel API object for ProductAPI
const cancelApiObject = defineCancelApiObject(ContractApi)