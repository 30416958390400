const initialState = {
    withdrawBalance:0,
    minWithdraw:0,
    limit24Hours:0,
    networkFee:'',
    network:1,
    symbol:'',
    contract:''
}

function WithdrawState(state  = initialState, dispatch){
    if(typeof dispatch !== 'object' || dispatch === null){
        let type = typeof dispatch;
        if (dispatch === null){
            type = null;
        }
        throw TypeError(`updateState() argument must be an object, or array of objects, not array of '${type}', or mixed.`);
    }

    let fields = []
    //let fieldName = "stateKey"
    let obj = {stateKey: state}
    if(dispatch.field !== undefined){
        fields = dispatch.field.split(".");
        //fieldName = fields.pop();
        obj = state;
        for(let field of fields){
            obj = obj[field]
        }
    }
    if(dispatch.type === "WITHDRAWBALANCE"){
        return {
            ...state,
            withdrawBalance: dispatch.payload
        }
    }
    else if(dispatch.type === "MINWITHDRAW"){
        return {
            ...state,
            minWithdraw: dispatch.payload
        }
    }
    else if(dispatch.type === "LIMIT24HOURS"){
        return {
            ...state,
            limit24Hours: dispatch.payload
        }
    }
    else if(dispatch.type === "NETWORKFEE"){
        return {
            ...state,
            networkFee: dispatch.payload
        }
    }
    else if(dispatch.type === "NETWORK"){
        return {
            ...state,
            network: dispatch.payload
        }
    }
    else if(dispatch.type === "SELECTSYMBOL"){
        return {
            ...state,
            symbol: dispatch.payload
        }
    }
    else if(dispatch.type === "SELECTCONTRACT"){
        return {
            ...state,
            contract: dispatch.payload
        }
    }

    return state;
}

function withdrawReducer(state = initialState, dispatches) {
    if(Array.isArray(dispatches)){
        // Do nothing
    }
    else if(typeof dispatches === 'object' && dispatches !== null){
        dispatches = [dispatches]
    }
    else {
        let type = typeof dispatches;
        if (dispatches === null){
            type = null;
        }
        throw TypeError(`updateReducer() argument must be an object, or array of objects, not '${type}'.`);
    }

    for(let dispatch of dispatches){
        state = WithdrawState(state, dispatch);
    }
    return state
}
export {withdrawReducer}