import React, {useState, useEffect, useCallback} from 'react';
import { useNavigate } from 'react-router-dom';
import { Nav, Tab } from "react-bootstrap";
import { Input, Popover, Radio, Modal} from "antd";
import {
  ArrowDownOutlined,
  DownOutlined,
  SettingOutlined,
} from "@ant-design/icons";
import AllTokenList from "./tokenList.json";
import { OraclePriceApi } from "../../Apis/OraclePriceApi";
import { AccountApi } from "../../Apis/AccountApi";
import CurrencyInput from 'react-currency-input-field';

function Swap(props) {

  const optNetwork = [
    {id: 0, text: 'Select Network'},
    {id: 1, text: 'CYX20 (Arrival time ≈ 1 - 5 minute)'},
    {id: 2, text: 'ERC20 (Arrival time ≈ 2 - 360 minute)'},
    {id: 3, text: 'BEP20 (Arrival time ≈ 2 - 360 minute)'},
    {id: 4, text: 'TRC20 (Arrival time ≈ 2 - 360 minute)'},
];

  const navigate = useNavigate();
  const baseUrl = process.env.PUBLIC_URL;
  const [isOpen, setIsOpen] = useState(false);
  const [changeToken, setChangeToken] = useState(1);
  const [errorMessage, setErrorMessage] = useState('\u00A0');
  const [swapdisable, setSwapdisable] = useState(true);
  const [lastdate, setLastDate] = useState('');
  const [balanceUpdate, setBalanceUpdate] = useState(true);
  const [swapStart, setSwapStart] = useState(false);
  const [calcError, setCalcError] = useState(false);
  const [feeAsset, setFeeAsset] = useState('');
  const [changeAvarage, setChangeAvarage] = useState(1);
  const [balanceZero, setBalanceZero] = useState(false);
  const [tokenList, setTokenList] = useState(AllTokenList);
  const [pageLoad, setPageLoad] = useState(true);
  
  const [form, setForm] = useState({
    toToken: tokenList[0],
    fromToken: tokenList[1],
    minMaxReceiveAmount:0,
    feeAmount:0,
    walletAddress:'',
    referenceCode:'',
    spTolerancePercent:0.5,
    spAmount:0,
    swapType:2,
    priceImpact:0,
    fromToTokenAvarage:0,
    accountAmount:0,
    hash:'',
    timeStamp:0,
    signature: [],
    status:true,
    message:'',
    network:0,
    recipient:'',
    maksAmountSelect:false,
    routeMap:[],
    toFromTokenAvarage:0,
    feePrice:0,
    block:0
  });

  const[request , setRequest] = useState({
    toToken: tokenList[0],
    fromToken: tokenList[1],
    wallet:'',
    referenceCode:'',
    swapType:2,
    spTolerancePercent:0.5,
    walletPinCode:'',
    network:1,
    recipient:'',
    maksAmountSelect:false,
    swapAmountType:1,
    block:0, 
    minMaxPrice:0,
    minFee:0,
    maxFee:0

  });

  const [formIninital, setFormIninital] = useState({
    toToken: form.toToken, fromToken: form.fromToken, minMaxReceiveAmount:0, feeAmount:0, walletAddress:'', referenceCode:'', spTolerancePercent:0.5,
    spAmount:0, swapType:2, priceImpact:0, fromToTokenAvarage:0, accountAmount:0, hash:'', timeStamp:0, signature: [], status:true, message:'', network:1,
    recipient:'', maksAmountSelect:false, routeMap:[], toFromTokenAvarage:0, feePrice:0, block:0
  });

  //Error Message
  const  Error = {
    'NoBanalce' : 'Insufficient liquidity for this trade.',
    'PinCode' : 'Password cannot be empty',
    'NetworkNull':'Network is Null'
  }

  //Amoun Valid
  function amountIsValid()
  {
    if(request?.toToken.amount!=0 && request?.toToken.amount!=null && request?.toToken.amount!='' && request.swapAmountType ==1)
      return true;

    if(request?.fromToken.amount!=0 && request?.fromToken.amount!=null && request?.fromToken.amount!='' && request.swapAmountType ==2)
      return true;

    return false;
  }

  //Page Return Login
  function returnLogin(){
    console.log("Login için Yönlendirme Yapılıyor...");
        sessionStorage.removeItem('AuthToken');
        sessionStorage.removeItem('AuthInformation');
        sessionStorage.removeItem('reduxState');
        navigate(baseUrl + '/sign-in')
  }

  //Login Valid
  function loginValid()
  {
    let AuthInformation = JSON.parse(sessionStorage.getItem('AuthInformation'));
    if(AuthInformation === null  || AuthInformation === undefined  ||  AuthInformation === "")
    {
        console.log("Login için Yönlendirme Yapılıyor...");
        sessionStorage.removeItem('AuthToken');
        sessionStorage.removeItem('AuthInformation');
        sessionStorage.removeItem('reduxState');
        navigate(baseUrl + '/sign-in')
        return false
    }
    else
      setRequest(prevState => ({...prevState, ["wallet"]: AuthInformation?.wallets[0].cyx20WalletAddress}));
    return true;
  }

  //Avarage Hide
  function priceAvarageHide()
  {
    var divis = document.getElementById('left-tabs-example-tabpane-preview1');
    if (divis) {
      if (!divis.classList.contains("hidediv")) {
          divis.classList.add("hidediv")
      }
    }
  }

  //Average Show
  function priceAvarageShow()
  {
    var divis = document.getElementById('left-tabs-example-tabpane-preview1');
    if (divis) {
      if (divis.classList.contains("hidediv")) {
          divis.classList.remove("hidediv")
      }
    }
  }

  //To Token Set Wallet Balance
  function toTokenSetWalletBalance(balance)
  {
    let token = request.toToken;
    token.walletBalance = balance;
    setRequest(prevState => ({...prevState, ["toToken"]: token}));
    setForm(prevState => ({...prevState, ["toToken"]: token}));
    //console.log(request.toToken.walletBalance);
  }

  //to Token Amount Clear
  function toTokenAmountClear()
  {
    let token = request.toToken;
    token.amount = null;
    setRequest(prevState => ({...prevState, ["toToken"]: token}));
    //console.log(request.toToken.amount);
  }

  //To Token Set Amount
  function toTokenSetAmount(_amount)
  {
    let token = request.toToken;
    token.amount = _amount;
    setRequest(prevState => ({...prevState, ["toToken"]: token}));
    //console.log(request.toToken.amount);
  }

  //From Token Amount Clear
  function fromTokenAmountClear()
  {
    let token = request.fromToken;
    token.amount = null;
    setRequest(prevState => ({...prevState, ["fromToken"]: token}));
    //console.log(request.fromToken.amount);
  }

  //From Token Set Amount
  function fromTokenSetAmount(_amount)
  {
    console.log(_amount);
    let token = request.fromToken;
    token.amount = _amount;
    setRequest(prevState => ({...prevState, ["fromToken"]: token}));
    //console.log(request.fromToken.amount);
  }

  //Form Clear
  function formClearBalance()
  {
    let toToken = form.toToken;
    toToken.amount = null;
    toToken.tokenTotalBalance =0;
    setForm(prevState => ({...prevState, ["toToken"]: toToken}));

    let fromToken = form.fromToken;
    fromToken.amount = null;
    fromToken.tokenTotalBalance =0;
    setForm(prevState => ({...prevState, ["fromToken"]: fromToken}));
    
    setForm(prevState => ({...prevState, ["maksAmountSelect"]: false}));
  }

  //Number Control
  function handleDecimalsOnValue(value) {
    const regex = /([0-9]*[\.]{0,1}[0-9]{0,8})/s;
    return value.match(regex)[0];
  }

  //Swap Error
  function swapCalculatorError(message)
  {
    setCalcError(true);
    setSwapdisable(true);
    setForm(formIninital);
    setForm(prevState => ({...prevState, ["routeMap"]: []}));
    setForm(prevState => ({...prevState, ["message"]: message}));
    setForm(prevState => ({...prevState, ["status"]: false})); 
    setRequest(prevState => ({...prevState, ["maksAmountSelect"]: false}));
    setErrorMessage(message);
    priceAvarageHide();
  }

  //Error Check Function
  function errorCheck() {
    var nowDate = new Date();

    // console.log(lastdate);
    // console.log(nowDate)
    //Amount is null Error
    if(!amountIsValid())
    {
      console.log('Balance Hatası');
      setSwapdisable(true);
      setErrorMessage('\u00A0');
      priceAvarageHide();
      formClearBalance();
      return false;
    }

    if (calcError) return false;

    if(balanceUpdate) return false;

    if(swapStart) return false;

    if(request?.toToken?.contract=="") return false;

    if(request?.fromToken?.contract=="") return false;

    //Geri Sayim Sayaci
    
    if(lastdate=='')
      setLastDate(nowDate);

    //Wallet Address is null Control
    if(request.wallet=='' || request.wallet==null || request.wallet == undefined )
    {
      loginValid();
    }

    //Global Controls
    if(request?.toToken?.amount > form?.toToken?.walletbalance && request.swapAmountType==1)
    {
      return false;
    }
    else if(form.swapType != 2)
    {
      return false;
    }
    else if(lastdate > nowDate)
    {
      //console.log(request.toToken);
      //console.log("return True");
      return false;
    }
    else
    {
      //console.log('Success');
      setErrorMessage('\u00A0');
      priceAvarageShow();
      nowDate.setSeconds(nowDate.getSeconds() + 30);
      setLastDate(nowDate);
      return true;
    }
  }

  //Change Slippage
  function handleSlippageChange(e){
    setRequest(prevState => ({...prevState, ["spTolerancePercent"]: e.target.value}));
  }

  //Change To Amount Input
  function changeToAmount() {
    console.log(request.toToken.amount);
    var val = request.toToken.amount;
    setFeeAsset(form.fromToken.symbol);
    setRequest(prevState => ({...prevState, ["swapAmountType"]: Number(1)}));
    setRequest(prevState => ({...prevState, ["maksAmountSelect"]: false}));
    setForm(prevState => ({...prevState, ["swapType"]: Number(2)}));
    setCalcError(false);
    toTokenAmountClear();
    fromTokenAmountClear();
    
    if(val =='' || val ==null)
    {
      setErrorMessage('\u00A0');
      formClearBalance();
      setBalanceZero(true);
      priceAvarageHide();
    }
    else
    {
      if(val !='' && val !=null)
      {
        const amount = val;
        setLastDate('');
        setBalanceZero(false);
        toTokenSetAmount(amount);
        fromTokenSetAmount(0);
      }
    }
  }

  //Chage From Amount Input
  function changeFromAmount(e) {
    console.log(e.target.value);
    var val = request.fromToken.amount;
    setFeeAsset(form.toToken.symbol);
    setRequest(prevState => ({...prevState, ["swapAmountType"]: Number(2)}));
    setRequest(prevState => ({...prevState, ["maksAmountSelect"]: false}));
    setForm(prevState => ({...prevState, ["swapType"]: Number(2)}));
    setCalcError(false);
    fromTokenAmountClear();
    toTokenAmountClear();

    if(val=='' || val==null)
    {
      setErrorMessage('\u00A0');
      formClearBalance();
      setBalanceZero(true);
      priceAvarageHide();
      //setRequest(prevState => ({...prevState, ["referenceCode"]: ''}));
    }
    else
    {
      if(val!='' && val!=null)
      {
        const amount = val;
        setLastDate('');
        setBalanceZero(false);
        fromTokenSetAmount(amount);
        toTokenSetAmount(0);
      }
    }
  }

  //Chage Token Switch
  function switchTokens(){
    priceAvarageHide();
    setErrorMessage('\u00A0');
    setBalanceUpdate(true);
    setCalcError(false);
    setLastDate('');
    setForm(formIninital);
    setRequest(prevState => ({...prevState, ["swapType"]: 2}))
    setRequest(prevState => ({...prevState, ["maksAmountSelect"]: false}));
    toTokenAmountClear()
    fromTokenAmountClear();
    formClearBalance();

    const one = request.toToken;
    const two = request.fromToken;

    setRequest(prevState => ({...prevState, ["toToken"]: two}))
    setRequest(prevState => ({...prevState, ["fromToken"]: one}))

    setBalanceUpdate(true);
    setLastDate('');
  }

  //Open Modal
  function openModal(asset){
    setChangeToken(asset);
    setIsOpen(true);
  }

  //Swap Call function
  function swapCall()
  {
    if(swapdisable)
      return;
    //var divis = document.getElementById('left-tabs-example-tabpane-preview1');
    if(request.walletPinCode==null || request.walletPinCode=='')
    {
      setSwapdisable(true);
      setErrorMessage(Error.PinCode);
      priceAvarageHide();
      //console.log("Hata 1");
    }
    else if(request.recipient!=null && request.recipient!='' && request.network==0)
    {

      setSwapdisable(true);
      setErrorMessage(Error.NetworkNull);
      priceAvarageHide();
      //console.log("Hata 2");
    }
    else if(amountIsValid())
    {
      if(request.swapType==5)
      {
        request.swapType=2;
        setRequest(prevState => ({...prevState, ["swapType"]: 2}));

      }
       
      if(request.swapType==2 && request.walletPinCode!= null && request.walletPinCode!='')
      {
        console.log("Swap.....");
        setSwapStart(true);
        setSwapdisable(true);
        setErrorMessage("Swap Pending");
        setRequest(prevState => ({...prevState, ["swapType"]: 3}));
        //setSwapdisable(true);
        //setForm(prevState => ({...prevState, ["swapType"]: 3}));
        //console.log(form);
        request.swapType=3; 
        //console.log('Swap Request');
        //console.log(request);
        OraclePriceApi.swap(request)
        .then((response) => {
          //console.log("Swap Response");
          //console.log(response);
          if(response?.status)
          {
            if(Number(response?.data?.swapType) == 4 )
            {
              //console.log("swapType 4");
              setLastDate('');
              setForm(formIninital);
              setForm(prevState => ({...prevState, ["toToken"]: request.toToken}));
              setForm(prevState => ({...prevState, ["fromToken"]: request.fromToken}));
              setRequest(prevState => ({...prevState, ["referenceCode"]: ''}));
              setRequest(prevState => ({...prevState, ["swapAmountType"]: Number(1)}));
              setRequest(prevState => ({...prevState, ["swapType"]: Number(2)}));
              setRequest(prevState => ({...prevState, ["maksAmountSelect"]: false}));
              toTokenAmountClear()
              fromTokenAmountClear();
              setBalanceUpdate(true);
              setSwapdisable(true);
              setErrorMessage('Swap Complete');
              formClearBalance();
              setBalanceZero(true);
              priceAvarageHide();
              //console.log(form);
            }
            else if(response?.data?.swapType==5)
            {
              //console.log("swapType 5");
              setRequest(prevState => ({...prevState, ["swapType"]: Number(2)}));
              setErrorMessage(response?.data?.message);
              setSwapdisable(false);
            }
            else
            {
              console.log("SwapType Error Eşleşen Swap Type Yok");
            }
          }
          else
          {
            console.log(response?.message);
            setErrorMessage(response?.message);
            setSwapdisable(false);
          }
          setSwapStart(false);
        })
        .catch((error)  => {
          setSwapStart(false);
          console.log("OraclePriceApi catch Hatası");
          console.log(error);

        });
      }
    }
  }

  //Trigger Timer and Balance Control
  useEffect(() => {
      const interval = setInterval(() => {
        var nowDate = new Date();
        if(amountIsValid() && !calcError)
        {
          if(lastdate > nowDate && form.swapType==2 && !swapStart)
          {
            if(Number(request.toToken.amount.toString().replace(',','.')) > 0 && Number(request.fromToken.amount.toString().replace(',','.'))> 0)
            {
              priceAvarageShow();
              let seconds = Math.round(lastdate - nowDate) / 1000;
              setErrorMessage(seconds.toFixed(0) + " seconds left for new price");
            }
            else
            {
              priceAvarageShow();
              setSwapdisable(true);
              setErrorMessage("Not Enough Amount for Fee");
            }
          }
          else if(lastdate > nowDate &&  form.swapType == 5 && !swapStart && Number(form.toToken.amount) > 0 && Number(form.fromToken.amount)> 0)
          {
            if(Number(request.toToken.amount.toString().replace(',','.')) > 0 && Number(request.fromToken.amount.toString().replace(',','.'))> 0)
            {
              priceAvarageShow();
              let seconds = Math.round(lastdate - nowDate) / 1000;
              setErrorMessage(seconds.toFixed(0) + " " + form.message + " " + " seconds left for new price!");
            }
            else
            {
              priceAvarageShow();
              setErrorMessage("Not Enough Amount for Fee");
            }
          }
          else
            formClearBalance();
        }

        if(balanceZero)
        {
          if(request?.fromToken.amount=='' || request?.fromToken.amount== null || request?.fromToken.amount==0)
          {
            if(request?.toToken.amount!='' && request?.toToken.amount!= null && request?.toToken.amount!=0)
            {
              toTokenAmountClear();
              setForm(formIninital);
              setBalanceUpdate(true);
              priceAvarageShow();
            }
          }

          if(request.toToken.amount=='' || request.toToken.amount== null || request.toToken.amount==0)
          {
            if(request.fromToken.amount!='' && request.fromToken.amount!= null && request.fromToken.amount!=0)
            {
              fromTokenAmountClear();;
              setForm(formIninital);
              setBalanceUpdate(true);
              priceAvarageShow();
            }
            
          }
        }

        if(pageLoad){
          setPageLoad(false);
          console.log('Clear Page Form');
          setRequest(prevState => ({...prevState, ["swapAmountType"]: Number(1)}));
          setRequest(prevState => ({...prevState, ["maksAmountSelect"]: false}));
          setForm(prevState => ({...prevState, ["swapType"]: Number(2)}));
          setCalcError(false);
          toTokenAmountClear();
          fromTokenAmountClear();
          formClearBalance();
          setBalanceUpdate(false);
          setErrorMessage('\u00A0');
          setBalanceZero(true);
          priceAvarageHide();
        }
          
      }, 1000);
      return () => clearInterval(interval);
  }, [errorMessage,lastdate,changeAvarage,balanceZero,balanceUpdate,request,form,swapdisable, swapStart, pageLoad]);

  //Get Swap Price Calculator
  useEffect(() => {
    if(amountIsValid())
    {
      var check = errorCheck();
      //console.log(check);
      if(check)
      {
        setSwapdisable(true);
        setForm(prevState => ({...prevState, ["routeMap"]: []}));
        console.log(request);
        OraclePriceApi.swap(request)
        .then((response) => {
          //console.log(response);
          if(response?.status)
          {
            if(response?.data?.status)
            {
                if(!balanceZero)
                {
                  //console.log("Sorgulanıyor");
                  //console.log(response?.data);

                  setRequest(prevState => ({...prevState, ["referenceCode"]: response?.data?.referenceCode}));
                  setRequest(prevState => ({...prevState, ["minMaxPrice"]: response?.data?.minMaxReceiveAmount}));
                  setRequest(prevState => ({...prevState, ["block"]: response?.data?.block}));
                  setRequest(prevState => ({...prevState, ["maksAmountSelect"]: false}));

                  var ToAmountCheck =true;
                  var FromAmountCheck = true;

                  if(request.swapAmountType==1 && Number(request.toToken.amount) == Number(response.data.toToken.amount))
                    fromTokenSetAmount(response.data.fromToken.amount);
                  // else 
                  if(request.swapAmountType==1 && Number(request.toToken.amount) != Number(response.data.toToken.amount))
                    ToAmountCheck=false;

                  if(request.swapAmountType==2 && Number(request?.fromToken.amount) == Number(response?.data?.fromToken.amount))
                    toTokenSetAmount(response?.data?.toToken.amount);
                  // else 
                  if(request.swapAmountType==2 && Number(request?.fromToken.amount) != Number(response?.data?.fromToken.amount))
                    FromAmountCheck =false;

                  if(!ToAmountCheck  || !FromAmountCheck)
                  return;

                  if(Number(response.data.toToken.amount)<0)
                  {
                    toTokenSetAmount(0);
                    response.data.toToken.amount=0;
                    response.data.toToken.toToken.tokenTotalBalance=0;
                  }
                  
                  if(Number(response.data.fromToken.amount)<0)
                  {
                    fromTokenSetAmount(0);
                    response.data.fromToken.amount=0;
                    response.data.fromToken.tokenTotalBalance=0;
                  }

                  response.data.fromToken.amount = response.data.fromToken.amount.toString().replace(',',".");
                  response.data.fromToken.amount = response.data.toToken.amount.toString().replace(',',".");
                  //setForm(response?.data)
                  setCalcError(false);
                  setForm(response?.data);
                  setSwapdisable(false);
                  setErrorMessage('\u00A0');
                }
                else
                {
                  setForm(formIninital);
                }
            }
            else
            {
              swapCalculatorError(response?.data?.message);
            }
          }
          else
          {
            swapCalculatorError(response?.message);
          }
        })
        // .catch((error)  => {
        //   //console.log("OraclePriceApi Swap catch Hatası");
        //   console.log(error);

        //   if(error?.response.status==401)
        //   {
        //     returnLogin();
        //   }

        // });
      }
    }
  }, [form, request, formIninital,balanceUpdate,feeAsset,changeAvarage,balanceZero, lastdate, errorMessage,swapStart]);

  //Update Balance
  useEffect(()=>{
    
    if(balanceUpdate && loginValid())
    {
      
      if(request.wallet != '' && request.wallet != null && request.wallet != undefined)
      {
        setBalanceUpdate(false);
        //console.log(request?.wallet);
        AccountApi.getTokenBalance({'address':request?.wallet, 'contract':request?.toToken.contract})
        .then((response) => {
          //console.log(response);
          if(response?.status)
          {
            
              var walletbalance = response?.data?.availableAmount.replace(',','.');
              toTokenSetWalletBalance(walletbalance);
              if(request.toToken.amount>0)
                setSwapdisable(false);

              if(Number(walletbalance)<=0)
                formClearBalance();
            
          }
          else
          {
            formClearBalance();
            setBalanceUpdate(false);
          }
          setBalanceUpdate(false);
        })
        .catch((error) => { 
          //console.log('Balance Sorgulanamadı.')
        });
      }
      // else
      // {
      //   //loginValid();
      //   setBalanceUpdate(false);
      // }
      
    }
  }, [form, request, formIninital,balanceUpdate, errorMessage,feeAsset,changeAvarage,balanceZero,swapStart])

  //Page Load
  useEffect(() => {
    const handleLoad = () => {
      console.log('Page Load  Function')
      toTokenAmountClear();
      fromTokenAmountClear();
      formClearBalance();
    };
    window.addEventListener('load', handleLoad);
    return () => {
      window.removeEventListener('load', handleLoad);
    };
  }, []);

  //Modifty Token && Token Change
  function modiftyToken(i){
    priceAvarageHide();
    setErrorMessage('\u00A0');
    setCalcError(false);
    setLastDate('');
    setForm(formIninital);
    setRequest(prevState => ({...prevState, ["swapType"]: 2}))
    setRequest(prevState => ({...prevState, ["maksAmountSelect"]: false}));
    setRequest(prevState => ({...prevState, ["referenceCode"]: ''}));
    toTokenAmountClear();
    fromTokenAmountClear();

   


    if(changeToken === 1){
      if(request.fromToken.contract== tokenList[i].contract)
        return;
      setRequest(prevState => ({...prevState, ["toToken"]: tokenList[i]}))
    } 
    else 
    {
      if(request.toToken.contract == tokenList[i].contract)
        return;
      setRequest(prevState => ({...prevState, ["fromToken"]: tokenList[i]}));

    }

    if(request.swapAmountType==1)
    setFeeAsset(tokenList[i].symbol);
  else
    setFeeAsset(tokenList[i].symbol);

    setIsOpen(false);
    setBalanceUpdate(true);
  }

  const settings = (
    <>
      <div>Slippage Tolerance</div>
        <Radio.Group value={form.spTolerancePercent} onChange={handleSlippageChange}>
          <Radio.Button value={0.1}>0.1%</Radio.Button>
          <Radio.Button value={0.5}>0.5%</Radio.Button>
          <Radio.Button value={1}>1.0%</Radio.Button>
        </Radio.Group>
    </>
  );

  //Password input
  function passwordHandleChange(e) {
    setSwapdisable(false);
    setErrorMessage('\u00A0');
    //console.log(e.target.value);
    setRequest(prevState => ({...prevState, ["walletPinCode"]: e.target.value}))
  };

  //Swap Transfer input change
  const recipientHandleChange = event => {
    setRequest(prevState => ({...prevState, ["recipient"]: event.target.value}))
  };

  //Swap Transfer network Change
  const networkHandleChange = event => {
    setRequest(prevState => ({...prevState, ["network"]: event.target.value}))
  }

  //A Token B Token Avarage Change
  const callopsChange = useCallback(async () => {
    if(changeAvarage==1)
      setChangeAvarage(2);
    else
      setChangeAvarage(1);
  }, [changeAvarage])


  //Show Hide Swap Calculator Datail
  const callopsOneEvent = useCallback(async () => {
    var divis = document.getElementById('collapseOne')
      if (divis) {
        if (divis.classList.contains("show")) {
            divis.classList.remove("show")
        } else {
            divis.classList.add("show")
        }
      }
  }, []) 

  //Show Hide Swap Transfer Detail
  const callopsTwoEvent = (id) => {
    var divis = document.getElementById(id)
    if (divis) {
        if (divis.classList.contains("show")) {
            divis.classList.remove("show")
        } else {
            divis.classList.add("show")
        }
    }
  }

  //Maksimum Swap
  const maksAmountHandle = () => {

    setErrorMessage('\u00A0');
    setCalcError(false);
    setLastDate('');
    
    setBalanceZero(false);

    
    var balance = request?.toToken?.walletBalance.replace(',','.');

    //console.log(balance);
    var maksAmount = Number(balance);
    //console.log(maksAmount);
    maksAmount = maksAmount.toString();
    //console.log(maksAmount);
    toTokenSetAmount(Number(maksAmount));
    fromTokenSetAmount(0);
    
    

    setRequest(prevState => ({...prevState, ["swapType"]: 2}));
    setRequest(prevState => ({...prevState, ["swapAmountType"]: 1 }));
    setRequest(prevState => ({...prevState, ["maksAmountSelect"]: true}));

    setForm(prevState => ({...prevState, ["toToken"]: request.toToken}));
    setForm(prevState => ({...prevState, ["fromToken"]: request.fromToken}));
  }

  return (
    <>
    <Modal
      open={isOpen}
      footer={null}
      onCancel={() => setIsOpen(false)}
      title="Select a token"
    >
      <div className='modalContent'>
        {tokenList?.map((e,i)=>{
          return(
            <div
            className='tokenChoice'
            key={i}
            onClick={() => modiftyToken(i)}>
              <img src={e.img} alt={e.symbol} className="tokenLogo" />
              <div className="tokenChoiceNames">
                <div className='tokenName'>{e.name}</div>
                <div className='tokenTicker'>{e.symbol}</div>
              </div>
            </div>
          );
        })}
      </div>
    </Modal>

    <div className="tradeBox">
      <div className='tradeBoxHeader'>
          <h4 className='h4Tag'>Swap  {form.toToken.symbol} </h4>
          <Popover
          content={settings}
          tittle="Settings"
          trigger="click"
          placement="bottomRight"
          className='h4Tag'
          >
          <SettingOutlined className="cog"/>
          </Popover>
       </div>
       <div className='inputs'>


          {/* <Input type='text' placeholder='0'  onChange={e => changeToAmount(e)} name='amount' pattern="^[0-9]*[.,]?[0-9]*$" value={request.toToken.amount} /> */}


          <CurrencyInput 
            decimalSeparator="." 
            className='ant-input css-dev-only-do-not-override-mxhywb'
            groupSeparator=","
            id="input-toAmount"
            name="amount"
            placeholder="0"
            value={request.toToken.amount}
            decimalsLimit={6}
            onValueChange={(value, name, values) => { toTokenSetAmount(value); }}
            onChange={changeToAmount}
            inputMode="text"
            maxLength="12"
          />



          <div  className='input-div'>{form.toToken.tokenTotalBalance}$</div> 
          <div  className='input-div-balance'>Balance:{request.toToken.walletBalance} <a href='#' onClick={maksAmountHandle} className='maks-button'>Maks</a> </div> 



          {/* <Input type='text' placeholder='0' onChange={e => changeFromAmount(e)} name='amount' pattern="^[0-9]*[.,]?[0-9]*$" value={request?.fromToken?.amount}/> */}


          <CurrencyInput 
            decimalSeparator="." 
            className='ant-input css-dev-only-do-not-override-mxhywb'
            groupSeparator=","
            id="input-fromAmount"
            name="amount"
            placeholder="0"
            value={request?.fromToken?.amount}
            decimalsLimit={6}
            onValueChange={(value, name, values) => { fromTokenSetAmount(value) }}
            onChange={changeFromAmount}
            inputMode="text"
            maxLength="12"
          />



          <div  className='input-div'>{form?.fromToken.tokenTotalBalance}$ {form?.spAmount < 0 &&<span className='colorRed'>({form?.spAmount}%)</span>}{form?.spAmount >= 0 &&<span>({form?.spAmount}%)</span>}</div> 
          <div className='switchButton' onClick={switchTokens}>
            <ArrowDownOutlined className="switchArrow" />
          </div> 
          <div className="assetOne" onClick={() => openModal(1)}>
            <img src={request.toToken.img} alt="assetOneLogo" className="assetLogo"/>
            {request.toToken.symbol}
            <DownOutlined/>
          </div>
          <div className='assetTwo' onClick={() => openModal(2)}>
            <img src={request.fromToken.img} alt="assetTwoLogo" className="assetLogo"/>
            {request.fromToken.symbol} 
            <DownOutlined/>
          </div>
      </div>

      <Tab.Container id="left-tabs-example" defaultActiveKey="preview1" className="px-12 w100">
          <Tab.Content className='w100'>
              <Tab.Pane eventKey="preview1" className='hidediv'>
                  <div className="card-body">
                      <div className="accordion" id="accordionExample">
                          <div className="card radius-2px">
                              <div className="card-header p-0" id="headingOne">

                              {form?.routeMap.map((e,i)=>{
                                  return(
                                    <h2 className='routeText' key={i}>
                                        {e.routeText} 
                                    </h2>
                                  );
                                })}




{/* 
                                {form?.routeMap.map(route => (
                                <h2 key={route.id}  className='routeText'>
                                      {route.routeText}
                                </h2>
                                ))} */}
                                  <h2 className="mb-0">
                                 
                                      <div className="btn btn-light btn-block text-start p-3 rounded-0 w100" >

                                        {changeAvarage == 1 &&
                                          <div className='w100'>
                                            <a href='#' onClick={callopsChange}><i className="icofont-exchange img-margin-right-blue"></i></a>
                                              1 {form.fromToken.symbol} = {form.fromToTokenAvarage} {form.toToken.symbol}
                                              <a href='#' onClick={callopsOneEvent}><i className="icofont-bubble-down divOrtayaHizala"></i></a>
                                          </div>
                                        }

                                        {changeAvarage == 2 &&
                                          <div className='w100'>
                                            <a href='#' onClick={callopsChange}><i className="icofont-exchange img-margin-right-blue"></i></a>
                                              1 {form.toToken.symbol} = {form.toFromTokenAvarage} {form.fromToken.symbol} 
                                              <a href='#' onClick={callopsOneEvent}><i className="icofont-bubble-down divOrtayaHizala"></i></a>
                                          </div>
                                        }
                                      </div>
                                    
                                  </h2>
                              </div>
                              <div id="collapseOne" className="collapse" data-parent="#accordionExample">
                                  <div className="card-body padding-sifir">
                                      <div className="table-responsive mt-1">
                                        <table className="table">
                                            <tbody>
                                              <tr>
                                                  <td>Fee Amount</td>
                                                  <td className='right'>({form?.feeAmount.toFixed(8)}) {feeAsset}</td>
                                              </tr>
                                              <tr>
                                                  <td>Fee Price</td>
                                                  <td className='right'>{form?.feePrice} USD</td>
                                              </tr>
                                              <tr>
                                                  <td>Price Impact</td>
                                                  <td className='right'>{form?.priceImpact}%</td>
                                              </tr>


                                              {request.swapAmountType == 1 &&
                                                 <tr>
                                                    <td>Minimum Output</td>
                                                    <td className='right'>{form?.minMaxReceiveAmount} {form?.fromToken.symbol}</td>
                                                </tr>
                                              }

                                              {request.swapAmountType == 2 &&
                                                <tr>
                                                    <td>Maximum Input</td>
                                                    <td className='right'>{form?.minMaxReceiveAmount} {form?.toToken.symbol}</td>
                                                </tr>
                                              }
                                              <tr>
                                                  <td>Expected output</td>
                                                  <td className='right'>{request?.fromToken.amount} {request?.fromToken.symbol}</td>
                                              </tr>
                                            </tbody>
                                          </table>
                                        </div>
                                  </div>
                              </div>
                          </div>
                      </div>
                  </div>
              </Tab.Pane>
          </Tab.Content>
      </Tab.Container>

      <Tab.Container id="left-tabs-example" defaultActiveKey="preview1" className="px-12 w100">
          <Tab.Content className='w100'>
              <Tab.Pane eventKey="preview1">
                  <div className="card-body">
                      <div className="accordion" id="accordionExample">
                          <div className="card radius-2px" style={{marginBottom:'10px', marginTop:'10px', borderRadius:'2px'}}>
                              <div className="card-header p-0" id="headingTwo">
                                  <h2 className="mb-0">
                                      <button className="btn btn-light btn-block text-start p-3 rounded-0 w100" style={{borderRadius:'5px !important'}} onClick={() => {callopsTwoEvent("collapseTwo"); }}>
                                      <i className="icofont-ui-add img-margin-right"></i>Add a send (optional)
                                      </button>
                                  </h2>
                              </div>
                              <div id="collapseTwo" className="collapse" data-parent="#accordionExample">
                                  <div className="card-body padding-sifir">
                                      <div className="table-responsive mt-1">
                                        <table className="table">
                                            <tbody>
                                            <tr>
                                                  <td>Withdraw Network</td>
                                            </tr>
                                            <tr>
                                                 <td>

                                                 <select  value={request.network} onChange={networkHandleChange} style={{backgroundColor: 'var(--primary-color)', color: 'var(--color-000)'}} className="form-select">
                                                    {optNetwork.map(network => (
                                                    <option key={network.id} value={network.id}>
                                                        {network.text}
                                                    </option>
                                                    ))}
                                                  </select>


                                                 </td>
                                            </tr>
                                            <tr>
                                                <td>recipient</td>
                                            </tr>
                                            <tr>
                                                <td><input type="text" name="recipient" placeholder='Wallet Address' className="form-control" value={request.recipient} onChange={recipientHandleChange} /></td>
                                            </tr>
                                            </tbody>
                                          </table>
                                        </div>
                                  </div>
                              </div>
                          </div>
                      </div>
                  </div>
              </Tab.Pane>
          </Tab.Content>
      </Tab.Container>

      <Tab.Container id="left-tabs-example" defaultActiveKey="preview1" className="px-12 w100">
          <Tab.Content className='w100'>
              <Tab.Pane eventKey="preview1">
                  <div className="card-body">
                      <div className="accordion" id="accordionExample">
                          <div className="card card-no-border-color">
                              <div className="card-header p-0" id="headingTheree">
                                  <h2 className="mb-0">
                                  <input type="password" name="walletPinCode" style={{borderRadius:'0px !important'}} placeholder='User Password' className="form-control radius-2px" value={request.walletPinCode} onChange={e => passwordHandleChange(e)}  />
                                  </h2>
                              </div>
                          
                          </div>
                      </div>
                  </div>
              </Tab.Pane>
          </Tab.Content>
      </Tab.Container>
      <div className="swapButton" disabled={swapdisable} onClick={() => {swapCall(); }}>Swap</div>
      <div className='errorMessage'>{errorMessage}</div>
    </div>
    </>
  );
}

export default Swap