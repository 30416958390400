export const FiatBalanceTable={
    columns:[
        {
            name: "Asset",
            selector: (row) => row.contractName,
            cell: row => <><img className="avatar rounded-circle mx-3" src={row.contractImage} alt="" /> <span>{row.contractName}</span></>,
            sortable: true, minWidth: "175px"
        },
        {
            name: "Total",
            selector: (row) => row.totalAmount,
            cell: row => <><span><div style={{fontSize:'14px', fontWeight:'bold'}}>{row.totalAmount}</div><div style={{fontSize:'14px', fontWeight:'bold', color:'#7d7979'}}>{row.totalBalance}</div></span></>,
            sortable: true, minWidth: "175px"
        },
        {
            name: "Available",
            selector: (row) => row.availableAmount,
            cell: row => <><span><div style={{fontSize:'14px', fontWeight:'bold'}}>{row.availableAmount}</div><div style={{fontSize:'14px', fontWeight:'bold', color:'#7d7979'}}>{row.availableBalance}</div></span></>,
            sortable: true, minWidth: "200px"
        },
        {
            name: "Collateral",
            selector: (row) => row.collateralAmount,
            cell: row => <><span><div>{row.collateralAmount}</div><div>{row.collateralBalance}</div></span></>,
            sortable: true, minWidth: "200px"
        },
        {
            name: "Stake",
            selector: (row) => row.stakeAmount,
            cell: row => <><span><div>{row.stakeAmount}</div><div>{row.stakeBalance}</div></span></>,
            sortable: true, minWidth: "200px"
        },
        {
            name: "Locked",
            selector: (row) => row.lockedAmount,
            cell: row => <><span><div>{row.lockedAmount}</div><div>{row.lockedBalance}</div></span></>,
            sortable: true, minWidth: "200px"
        },
    ]
}