import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { AccountApi } from "../../Apis/AccountApi";
import { Nav, Tab } from 'react-bootstrap';
import Chart from "react-apexcharts";
import { CryptoBalanceTable } from './CryptoBalanceTable';
import { FiatBalanceTable } from './FiatBalanceTable';
import DataTable from 'react-data-table-component';
import { NumericFormat } from 'react-number-format';
import PlaceholderLoading from 'react-placeholder-loading'

function BalanceDetails(props) {

    Number.prototype.format = function(n, x, s, c) {
        var re = '\\d(?=(\\d{' + (x || 3) + '})+' + (n > 0 ? '\\D' : '$') + ')',
            num = this.toFixed(Math.max(0, ~~n));
        
        var format = (c ? num.replace('.', c) : num).replace(new RegExp(re, 'g'), '$&' + (s || ','));
        //console.log(format);
        format = format.replaceAll(',','c');
        //console.log(format);
        format = format.replaceAll('.',',');
        //console.log(format);
        format = format.replaceAll('c','.');
        //console.log(format);
        return format;
    };

    const customStyles = {
        table: {
            style: {
                color:'var(--text-color)',
                backgroundColor: '#00000000 !important',
            },
        },
        rows: {
            style: {
                backgroundColor: '#00000000 !important',
                outline: '1px solid var(--card-color)',
                color: 'var(--text-color)',
            },
            highlightOnHoverStyle: {
                backgroundColor: 'var(--card-color)',
                color: 'var(--text-color)',
                outline: '1px solid var(--card-color)',
            },
        },
        headCells:{
            style: {
                backgroundColor: 'var(--body-color)',
                color: 'var(--text-color)'
            }
        },
        noData: {
            style: {
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
                color: 'var(--text-color)',
                backgroundColor: 'var(--card-color)',
            },
        },
    };

    const [totalList, setTotalList] = useState(
        { 
            sumTotalBalance:"0",
            fiatTotalBalance:"0",
            cryptoTotalBalance:"0",
            cryptoCollateralBalance:"0",
            fiatAvailableBalance:"0",
            cryptoStakeBalance:"0",
            sumLockedBalance:"0",
            cryptoAvailableBalance:"0",
            fiatAvailableBalance:"0"
        });
    const [cryptoList, setCryptoList] = useState([]);
    const [fiatList, setFiatList] = useState([]);
    const [loading, setLoading] = useState(true);

    function cyxDetay(address) {
        AccountApi.getByAllAssetsToken({ 'address': address })
            .then((response) => {
                //console.log(JSON.stringify(response));
                if (Number(response?.code) == 0) {
                    //console.log(response?.data?.cryptoAccountBalances)
                    setTotalList(response?.data);
                    setCryptoList(response?.data?.cryptoAccountBalances);
                    setFiatList(response?.data?.fiatAccountBalances);
                }

                setLoading(false);
            });
    }

    useEffect(() => {
        const interval = setInterval(() => {
            let AuthInformation = JSON.parse(sessionStorage.getItem('AuthInformation'));
            if (typeof AuthInformation !== 'undefined' && AuthInformation) {
                cyxDetay(AuthInformation?.wallets[0].cyx20WalletAddress)
            };
        }, 1000);
        return () => clearInterval(interval);
    }, [totalList,cryptoList,fiatList]);

    const [options, setOptions] = useState({
        chart: {
            height: 250,
            type: 'donut',
        },
        dataLabels: {
            enabled: false,
        },
        legend: {
            position: 'top',
            horizontalAlign: 'center',
            show: true,
        },
        labels: ['Crypto', 'Cash'],
        colors: ['var(--primary-color)', 'var(--chart-color2)', 'var(--chart-color3)', 'var(--chart-color4)'],
        responsive: [{
            breakpoint: 480,
            options: {
                chart: {
                    width: 200
                },
                legend: {
                    position: 'bottom'
                }
            }
        }]
    });

    const [series, setSeries] = useState([100]);

    return (<>
        <div className="col-xl-6 col-xxl-7">
            <div className="card" >
                <Tab.Container defaultActiveKey="first">
                    <div className="card-header py-3 d-flex justify-content-between bg-transparent border-bottom align-items-center flex-wrap">
                        <h6 className="mb-0 fw-bold">Balance Details</h6>
                        <Nav className="nav nav-tabs tab-body-header rounded d-inline-flex mt-2 mt-md-0" role="tablist">
                            <Nav.Item className="nav-item" style={{borderRadius:'3px !important'}}><Nav.Link className="nav-link " eventKey="first" data-bs-toggle="tab" style={{borderRadius:'3px !important'}} href="#Total" role="tab">Total</Nav.Link></Nav.Item>
                            <Nav.Item className="nav-item" style={{borderRadius:'3px !important'}}><Nav.Link className="nav-link" eventKey="second" data-bs-toggle="tab" style={{borderRadius:'3px !important'}}  href="#Cyx" role="tab">Crypto</Nav.Link></Nav.Item>
                            <Nav.Item className="nav-item" style={{borderRadius:'3px !important'}}><Nav.Link className="nav-link" eventKey="third" data-bs-toggle="tab" style={{borderRadius:'3px !important'}}  href="#Btcc" role="tab">Cash</Nav.Link></Nav.Item>
                        </Nav>
                    </div>
                    <div className="card-body">
                        <Tab.Content className="tab-content">
                            <Tab.Pane className="tab-pane fade show" id="Total" eventKey="first">
                                <div className="row g-3">
                                    <div className="col-lg-6">
                                        <div>Total balance:</div>
                                        {!loading ? (
                                            <h4>${Number(totalList?.sumTotalBalance.replace(',','.')).format(2, 3, '.', ',') } </h4>
                                        ) : (
                                             <h4> <PlaceholderLoading colorStart='#0f141f' colorEnd='#8f8f8f' shape="rect" width={80} height={20} /></h4>
                                        )}
                                       
                                       
                                        <div>Cash balance:</div>
                                        {!loading ? (
                                            <h5>${Number(totalList?.fiatTotalBalance.replace(',','.')).format(2, 3, '.', ',') }</h5>
                                        ) : (
                                             <h5> <PlaceholderLoading colorStart='#0f141f' colorEnd='#8f8f8f' shape="rect" width={80} height={20} /></h5>
                                        )}
                                       
                                        
                                        
                                        <div>Cyrpto balance:</div>
                                        {!loading ? (
                                             <h5>${Number(totalList?.cryptoTotalBalance.replace(',','.')).format(2, 3, '.', ',') }</h5>
                                        ) : (
                                             <h5> <PlaceholderLoading colorStart='#0f141f' colorEnd='#8f8f8f' shape="rect" width={80} height={20} /></h5>
                                        )}
                                       

                                        <div className="mt-3 pt-3 text-uppercase text-muted pt-2 small">Collateral Balance</div>
                                        {!loading ? (
                                            <h6 style={{ color: '#ffb01a' }}>${Number(totalList?.cryptoCollateralBalance.replace(',','.')).format(2, 3, '.', ',') } </h6>
                                        ) : (
                                             <h6> <PlaceholderLoading colorStart='#0f141f' colorEnd='#8f8f8f' shape="rect" width={60} height={15} /></h6>
                                        )}
                                        
                                       


                                        <div className="mt-3 text-uppercase text-muted small">Cyrpto Stake Balance</div>
                                        {!loading ? (
                                             <h6 style={{ color: '#ffb01a' }}>${Number(totalList?.cryptoStakeBalance.replace(',','.')).format(2, 3, '.', ',') }</h6>
                                        ) : (
                                             <h6> <PlaceholderLoading colorStart='#0f141f' colorEnd='#8f8f8f' shape="rect" width={60} height={15} /></h6>
                                        )}
                                       
                                        


                                        <div>Locked balance:</div>
                                        {!loading ? (
                                             <h5 style={{ color: '#ffb01a' }}>${Number(totalList?.sumLockedBalance.replace(',','.')).format(2, 3, '.', ',') }</h5>
                                        ) : (
                                             <h5> <PlaceholderLoading colorStart='#0f141f' colorEnd='#8f8f8f' shape="rect" width={80} height={20} /></h5>
                                        )}
                                       
                                        


                                        <div>Available Cyrpto Balance</div>
                                        {!loading ? (
                                            <h5 style={{ color: '#11cb81' }}>${Number(totalList?.cryptoAvailableBalance.replace(',','.')).format(2, 3, '.', ',') }</h5>
                                        ) : (
                                             <h5> <PlaceholderLoading colorStart='#0f141f' colorEnd='#8f8f8f' shape="rect" width={80} height={20} /></h5>
                                        )}
                                        
                                        


                                        <div>Available Cash balance:</div>

                                        {!loading ? (
                                             <h5 style={{ color: '#11cb81' }}>${Number(totalList?.fiatAvailableBalance.replace(',','.')).format(2, 3, '.', ',') }</h5>
                                        ) : (
                                             <h5> <PlaceholderLoading colorStart='#0f141f' colorEnd='#8f8f8f' shape="rect" width={80} height={20} /></h5>
                                        )}
                                       

                                       
                                        
                                    </div>

                                   

                                </div>
                            </Tab.Pane>
                            
                            <Tab.Pane className="tab-pane fade" id="second" eventKey="second">
                                <div className="row g-12">
                                    <div className="col-lg-12">
                                        <div className="row g-3 mb-3 row-deck">
                                            <div className="col-xl-12" style={{padding:'0px'}}>
                                                <div className="card" style={{padding:'0px', borderWidth:'0px'}}>
                                                    <div className="card-body" style={{padding:'0px'}}>
                                                        <div id="ordertabthree_wrapper" className="dataTables_wrapper dt-bootstrap5 no-footer">
                                                            <div className="row">
                                                                <div className="col-sm-12">
                                                                    <DataTable
                                                                        columns={CryptoBalanceTable.columns}
                                                                        data={cryptoList}
                                                                        defaultSortField="contractName"
                                                                        selectableRows={false}
                                                                        highlightOnHover={true}
                                                                        customStyles={customStyles}
                                                                    />
                                                                </div>
                                                            </div>
                                                            {/* <div className="row mt-3 ">
                                                                <div className="col-sm-12 col-md-5" style={{ float: 'left' }}>
                                                                    <div className="dataTables_info" id="myDataTable_info" role="status" aria-live="polite">Showing 1 to 7 of 7 entries</div>
                                                                </div>
                                                                <div className="col-sm-12 col-md-7 " >
                                                                    <div className="dataTables_paginate paging_simple_numbers" id="myDataTable_paginate" style={{ float: 'right' }}>
                                                                        <ul className="pagination">
                                                                            <li className="paginate_button page-item previous disabled" id="myDataTable_previous"><a href="#!" className="page-link">Previous</a></li>
                                                                            <li className="paginate_button page-item active"><a href="#!" className="page-link">1</a></li>
                                                                            <li className="paginate_button page-item next disabled" id="myDataTable_next"><a href="#!" className="page-link">Next</a></li>
                                                                        </ul>
                                                                    </div>
                                                                </div>
                                                            </div> */}
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </Tab.Pane>

                            <Tab.Pane className="tab-pane fade" id="third" eventKey="third">
                                <div className="row g-12">
                                    <div className="col-lg-12">
                                        <div className="row g-3 mb-3 row-deck">
                                            <div className="col-xl-12" style={{padding:'0px'}}>
                                                <div className="card" style={{padding:'0px', borderWidth:'0px'}}>
                                                    <div className="card-body" style={{padding:'0px'}}>
                                                        <div id="ordertabthree_wrapper" className="dataTables_wrapper dt-bootstrap5 no-footer">
                                                            <div className="row">
                                                                <div className="col-sm-12">
                                                                    <DataTable
                                                                        columns={FiatBalanceTable.columns}
                                                                        data={fiatList}
                                                                        defaultSortField="contractName"
                                                                        selectableRows={false}
                                                                        highlightOnHover={true}
                                                                        customStyles={customStyles}
                                                                    />
                                                                </div>
                                                            </div>
                                                           
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </Tab.Pane>
                        </Tab.Content>
                    </div>
                </Tab.Container>
            </div>
        </div>
    </>
    )
}

const mapStateToProps = ({ marketReducer }) => ({

    marketReducer
})

export default connect(mapStateToProps, {

})(BalanceDetails);