import { api } from "./configs/axiosConfigs"
import { defineCancelApiObject } from "./configs/axiosUtils"

export const AccountApi = {
    register: async function (data, cancel = false) {
        const response = await api.request({
            url: `Account/Register`,
            method: "POST",
            data: JSON.stringify(data), 
                headers:{'Content-Type': 'application/json; charset=utf-8'},
            signal: cancel ? cancelApiObject[this.register.name].handleRequestCancellation().signal : undefined,
        })

        return response.data
    },
    forgotPassword: async function (data, cancel = false) {
        const response = await api.request({
            url: `Account/ForgotPassword`,
            method: "POST",
            data: JSON.stringify(data), 
                headers:{'Content-Type': 'application/json; charset=utf-8'},
            signal: cancel ? cancelApiObject[this.forgotPassword.name].handleRequestCancellation().signal : undefined,
        })

        return response.data
    },
    login: async function (data, cancel = false) {
        const response = await api.request({
            url: `Account/Login`,
            method: "POST",
            data: JSON.stringify(data), 
                headers:{'Content-Type': 'application/json; charset=utf-8'},
            signal: cancel ? cancelApiObject[this.login.name].handleRequestCancellation().signal : undefined,
        })

        return response.data
    },
    security: async function (data, cancel = false) {
        let AuthInformation = JSON.parse(sessionStorage.getItem('AuthInformation'));
        const response = await api.request({
            url: `Account/AccountSecurity`,
            method: "POST",
            data: JSON.stringify(data), 
            headers:{
                'Content-Type': 'application/json; charset=utf-8',
                'Authorization' : `Bearer ${AuthInformation?.token}`
            },
            signal: cancel ? cancelApiObject[this.security.name].handleRequestCancellation().signal : undefined,
        })

        return response.data
    },
    setGoogle2FA: async function ({code}, cancel = false) {
        console.log(code);
        let AuthInformation = JSON.parse(sessionStorage.getItem('AuthInformation'));
        const response = await api.request({
            url: `Account/SetGoogle2FA?code=${code}`,
            method: "GET",
            headers:{
                'Authorization' : `Bearer ${AuthInformation?.token}`
            },
            signal: cancel ? cancelApiObject[this.security.name].handleRequestCancellation().signal : undefined,
        })
        return response.data
    },
    removeGoogle2FA: async function ({code}, cancel = false) {
        console.log(code);
        let AuthInformation = JSON.parse(sessionStorage.getItem('AuthInformation'));
        const response = await api.request({
            url: `Account/RemoveGoogle2FA?code=${code}`,
            method: "GET",
            headers:{
                'Authorization' : `Bearer ${AuthInformation?.token}`
            },
            signal: cancel ? cancelApiObject[this.security.name].handleRequestCancellation().signal : undefined,
        })
        return response.data
    },
    getCyxBalance: async function ({address}, cancel = false) {
            const response = await api.request({
                url: `Account/GetCYXBalance/contract?address=${address}`,
                method: "GET",
                signal: cancel ? cancelApiObject[this.getCyxBalance.name].handleRequestCancellation().signal : undefined,
            })
            return response.data
    },
    getBtccBalance: async function ({address}, cancel = false) {
        const response = await api.request({
            url: `Account/GetBtccBalance/contract?address=${address}`,
            method: "GET",
            signal: cancel ? cancelApiObject[this.getBtccBalance.name].handleRequestCancellation().signal : undefined,
        })
        return response.data
    },
    getEthcBalance: async function ({address}, cancel = false) {
        const response = await api.request({
            url: `Account/GetEthcBalance/contract?address=${address}`,
            method: "GET",
            signal: cancel ? cancelApiObject[this.getEthcBalance.name].handleRequestCancellation().signal : undefined,
        })
        return response.data
    },
    getUsdtBalance: async function ({address}, cancel = false) {
        const response = await api.request({
            url: `Account/GetUsdtBalance/contract?address=${address}`,
            method: "GET",
            signal: cancel ? cancelApiObject[this.getUsdtBalance.name].handleRequestCancellation().signal : undefined,
        })
        return response.data
    },
    getUsdcBalance: async function ({address}, cancel = false) {
        const response = await api.request({
            url: `Account/GetUsdcBalance/contract?address=${address}`,
            method: "GET",
            signal: cancel ? cancelApiObject[this.getUsdcBalance.name].handleRequestCancellation().signal : undefined,
        })
        return response.data
    },
    getUsdcBalance: async function ({address}, cancel = false) {
        const response = await api.request({
            url: `Account/GetUsdcBalance/contract?address=${address}`,
            method: "GET",
            signal: cancel ? cancelApiObject[this.getUsdcBalance.name].handleRequestCancellation().signal : undefined,
        })
        return response.data
    },
    getTokenBalance: async function ({address , contract}, cancel = false) {
        let AuthInformation = JSON.parse(sessionStorage.getItem('AuthInformation'));
        const response = await api.request({
            url: `Account/GetTokenBalance/contract?address=${address}&contract=${contract}`,
            method: "GET",
            headers:{
                'Content-Type': 'application/json; charset=utf-8',
                'Authorization' : `Bearer ${AuthInformation?.token}`
            },
            signal: cancel ? cancelApiObject[this.getTokenBalance.name].handleRequestCancellation().signal : undefined,
        })
        return response.data
    },
    getByAllAssetsToken: async function ({ address }, cancel = false) {
        let AuthInformation = JSON.parse(sessionStorage.getItem('AuthInformation'));
        const response = await api.request({
            url: `Account/GetByAllAssetsToken/address?address=${address}`,
            method: "GET",
            headers:{
                'Content-Type': 'application/json; charset=utf-8',
                'Authorization' : `Bearer ${AuthInformation?.token}`
            },
            signal: cancel ? cancelApiObject[this.getByAllAssetsToken.name].handleRequestCancellation().signal : undefined,
        })
        return response.data
    },
    emailConfirm: async function ({email , code}, cancel = false) {
        const response = await api.request({
            url: `Account/EmailConfirm/Verification?email=${email}&code=${code}`,
            method: "GET",
            signal: cancel ? cancelApiObject[this.emailConfirm.name].handleRequestCancellation().signal : undefined,
        })
        return response.data
    },
}

// defining the cancel API object for ProductAPI
const cancelApiObject = defineCancelApiObject(AccountApi)