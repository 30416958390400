import  { combineReducers }   from "redux";
import { mainReducer } from "./MainReducer";
import { marketReducer } from './MarketReducer';
import { withdrawReducer } from './WithdrawReducer';


// const rootReducer = combineReducers({
   
//   });
    
//   export default rootReducer;


  const allReducers = combineReducers({
    mainReducer,
    marketReducer,
    withdrawReducer
})
export default allReducers