import React, { useEffect } from "react";
import { connect } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import BalanceDetails from "../../Component/Dashboard/BalanceDetails";
import ChartSmallCard from '../../Component/Dashboard/ChartSmallCard';
import Deposit from "../../Component/Dashboard/Deposit";
import Transaction from "../../Component/Dashboard/Transaction";
import Transfer from "../../Component/Dashboard/Transfer";
import WithdrawCrypto from "../../Component/Dashboard/WithdrawCrypto";
import UserDetailcard from '../../Component/Dashboard/UserDetailcard';
import DashBoardTopCard from "../../Component/Dashboard/DashBoardTopCard";
import { SpeechToTextComponent } from '../../Component/Dashboard/SpeechToTextComponent';


function Dashboard(props) {

    const navigate = useNavigate();
    var baseUrl = process.env.PUBLIC_URL
    
    useEffect(() => {

        let AuthToken = sessionStorage.getItem('AuthToken');

        if (AuthToken === null) {   
            navigate(baseUrl + '/sign-in')
        }
    }, [baseUrl,navigate,props]);

    return (
        <div className="container-xxl">
            <UserDetailcard />
            <ChartSmallCard />
            <div className="card mb-3">
                <DashBoardTopCard />
            </div>
            <div className="row g-3 mb-3 row-deck">
                <BalanceDetails />
                <Transfer/>
            </div>
            <div className="row g-3 mb-3 row-deck">
                <Deposit />
                <WithdrawCrypto />
            </div>

            <div className="row">
                <SpeechToTextComponent></SpeechToTextComponent>
            </div>

            <div className="row">
                <Transaction/>
            </div>

           

        </div>
    )
}

const mapStateToProps = ({ mainReducer }) => ({
    mainReducer
  })
  
export default connect(mapStateToProps, {
    
})(Dashboard);