import { Tab,Nav } from 'react-bootstrap';
import React from 'react';
import OtcPortalLimit from '../../Component/Trade/Convert/OtcPortalLimit';
import OtcPortalMarket from '../../Component/Trade/Convert/OtcPortalMarket';

function Convert() {
    return (
        <>
            <div className="container-xxl">
                <div className="row">
                    <div className="col-xl-12">
                        <div className="card">
                        <Tab.Container defaultActiveKey='first'>
                            <div className="card-header py-3 d-flex justify-content-between bg-transparent align-items-center flex-wrap border-bottom">
                                <h6 className="mb-0 fw-bold">Market</h6>
                                <Nav className="nav nav-tabs tab-body-header rounded d-inline-flex mt-2 mt-md-0" role="tablist">
                                    {/* <Nav.Item className="nav-item"><Nav.Link className="nav-link" eventKey='first' data-bs-toggle="tab" href="#Limit" role="tab">Limit</Nav.Link></Nav.Item> */}
                                    <Nav.Item className="nav-item"><Nav.Link className="nav-link" eventKey='first' data-bs-toggle="tab" href="#Market" role="tab">Market</Nav.Link></Nav.Item>
                                </Nav>
                            </div>
                            <div className="card-body">
                               
                                <Tab.Content className="tab-content">
                                    {/* <Tab.Pane className="tab-pane fade" id="Limit" eventKey='first'>
                                        <div className="row g-3">
                                            <OtcPortalLimit />
                                        </div>
                                    </Tab.Pane> */}
                                    <Tab.Pane className="tab-pane fade" id="Market" eventKey='first'>
                                        <div className="row g-3">
                                            <OtcPortalMarket />
                                        </div>
                                    </Tab.Pane>
                                </Tab.Content>
                            </div>
                            </Tab.Container>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}

export default Convert;