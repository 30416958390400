import React from 'react';
import axios from "axios"
import { notification } from "antd"



export const api = axios.create({

    //baseURL: "https://192.168.1.102:7159/",
    baseURL: "https://cyxapi.azurewebsites.net/",
    //baseURL: "http://20.91.188.145:7159/",
})

export const binanceApi = axios.create({
    baseURL: "https://api.binance.com/api/v3/",
})

// defining a custom error handler for all APIs
const errorHandler = (error) => {
    //const navigate = useNavigate();
    const navbaseUrl = process.env.PUBLIC_URL;
    const statusCode = error.response?.status
    //console.log(error);
    if (error.code === "ERR_CANCELED") {
        notification.error({
            placement: "bottomRight",
            description: "API canceled!",
        })
        return Promise.resolve()
    }

    // logging only errors that are not 401
    if (statusCode && statusCode === 401) { 
        //console.log(statusCode);
        console.log('401 Error')
        sessionStorage.removeItem('AuthToken');
        sessionStorage.removeItem('AuthInformation');
        sessionStorage.removeItem('reduxState');
        //navigate(navbaseUrl + '/sign-in')
    }

    if (statusCode && statusCode === 500) {
        //console.log("500 geldi");
        notification.error({
            placement: "bottomRight",
            description: JSON.stringify(error.response?.data.message),
        })
        //console.error(JSON.stringify(error.response?.data.message))
        return Promise.resolve(error);
    }

    //return Promise.reject(createError('Network Error', null, null, error));

    return Promise.reject(error)
}

// registering the custom error handler to the
// "api" axios instance
api.interceptors.response.use(undefined, (error) => {
    return errorHandler(error)
})

binanceApi.interceptors.response.use(undefined, (error) => {
    return errorHandler(error)
})