import React, { useState, useEffect, useRef } from 'react';
import {  useNavigate } from 'react-router-dom';
import * as sdk from 'microsoft-cognitiveservices-speech-sdk';

const SPEECH_KEY = '5fe0c871df414f12a50a044403f276e0';
const SPEECH_REGION = 'swedencentral';

export function SpeechToTextComponent() {

  const navigate = useNavigate();
  const navbaseUrl = process.env.PUBLIC_URL;

  const [isListening, setIsListening] = useState(false);
  const speechConfig = useRef(null);
  const audioConfig = useRef(null);
  const recognizer = useRef(null);

  const [myTranscript, setMyTranscript] = useState("");
  const [recognizingTranscript, setRecTranscript] = useState("");

  useEffect(() => {
    speechConfig.current = sdk.SpeechConfig.fromSubscription(
      SPEECH_KEY,
      SPEECH_REGION
    );
    speechConfig.current.speechRecognitionLanguage = 'en-US';

    //speechConfig.current.speechRecognitionLanguage = 'tr-TR';

    audioConfig.current = sdk.AudioConfig.fromDefaultMicrophoneInput();
    recognizer.current = new sdk.SpeechRecognizer(
      speechConfig.current,
      audioConfig.current
    );

    const processRecognizedTranscript = (event) => {
      const result = event.result;
      console.log('Recognition result:', result);

      if (result.reason === sdk.ResultReason.RecognizedSpeech) {
        const transcript = result.text;
        console.log('Transcript: -->', transcript);
        // Call a function to process the transcript as needed
        // transcript = transcript.toLowerCase();
        // transcript = transcript.replace(',','');
        // transcript = transcript.replace('.','');
        setMyTranscript(transcript);
        //let lowercasetext = text.toLowerCase(); //To convert Lower Case
        console.log(" Sonuc : " + transcript);
        if(transcript == "hey exchange")
        {
            navigate(navbaseUrl + '/exchange')
        }
        else if(transcript == "hey dashboard")
        {
            navigate(navbaseUrl + '/');
        }
      }
    };

    const processRecognizingTranscript = (event) =>{
        const result = event.result;
        console.log('Recognition result:', result);
        if (result.reason === sdk.ResultReason.RecognizingSpeech) {
            const transcript = result.text;
            console.log('Transcript: -->', transcript);
            // Call a function to process the transcript as needed

            // transcript = transcript.toLowerCase();
            // transcript = transcript.replace(',','');
            // transcript = transcript.replace('.','');
            setMyTranscript(transcript);
            //let lowercasetext = text.toLowerCase(); //To convert Lower Case
            console.log(" Sonuc : " + transcript);
            if(transcript == "hey exchange")
            {
                navigate(navbaseUrl + '/exchange');
            }
            else  if(transcript == "hey dashboard")
            {
                navigate(navbaseUrl + '/');
            }
    
            setRecTranscript(transcript);
        }
    }

    recognizer.current.recognized = (s, e) => processRecognizedTranscript(e);
    recognizer.current.recognizing = (s, e) => processRecognizingTranscript(e);


    recognizer.current.startContinuousRecognitionAsync(() => {
      console.log('Speech recognition started.');
      setIsListening(true);
    });

    return () => {
      recognizer.current.stopContinuousRecognitionAsync(() => {
        setIsListening(false);
      });
    };
  }, []);

//   const pauseListening = () => {
//     setIsListening(false);
//     recognizer.current.stopContinuousRecognitionAsync();
//     console.log('Paused listening.');
//   };

  const resumeListening = () => {
    if (!isListening) {
      setIsListening(true);
      recognizer.current.startContinuousRecognitionAsync(() => {
        console.log('Resumed listening...');
      });
    }
  };

  const stopListening = () => {
    setIsListening(false);
    recognizer.current.stopContinuousRecognitionAsync(() => {
      console.log('Speech recognition stopped.');
    });
  };

  return (
    <div>
      {/* <button onClick={pauseListening}>Pause Listening</button> */}
      {/* <button onClick={resumeListening}>Start Listening</button>
      <button onClick={stopListening}>Stop Listening</button>

      <div>
        <div>
            Recognizing Transcript : {recognizingTranscript}
        </div>

        <div>
            RecognizedTranscript : {myTranscript}
        </div>
      </div>  */}
    </div>
  );
};