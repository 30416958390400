import React, { useState , useEffect } from "react";
import { onBTCCPrice, onETHCPrice, onCYXPrice, onUSDTPrice,onUSDCPrice } from '../../Redux/Actions/marketAction';
import { BinanceApi } from "../../Apis/BinanceApi";

function ChartSmallCard(props) {
    
    const { ethcPrice, cyxPrice ,usdtPrice, usdcPrice, btccPrice } = props;
    //console.log(props)
    //CYX
    const [cyxPrices, setCyxPrices] = useState(0.91);
    const [cyxChangePercent, setCyxChangePercent] = useState(0);
    const [cyxVolume, setCyxVolume] = useState(0);
    const [cyxUpDown, setCyxUpDown] = useState('color-price-up');
    const [cyxPercentUpDown, setCyxPercentUpDown] = useState('text-success');
    const [cyxIconUpDown, setCyxIconUpDown] = useState('fa-level-up');

    //BTCC
    const [btcPrice, setBtcPrice] = useState(0);
    const [btcChangePercent, setBtcChangePercent] = useState(0);
    const [btcVolume, setBtcVolume] = useState(0);
    const [btcUpDown, setBtcUpDown] = useState('color-price-up');
    const [btcPercentUpDown, setBtcPercentUpDown] = useState('text-success');
    const [btcIconUpDown, setBtcIconUpDown] = useState('fa-level-up');
    //ETHC
    const [ethPrice, setEthPrice] = useState(0);
    const [ethChangePercent, setEthChangePercent] = useState(0);
    const [ethVolume, setEthVolume] = useState(0);
    const [ethUpDown, setEthUpDown] = useState('color-price-up');
    const [ethPercentUpDown, setEthPercentUpDown] = useState('text-success');
    const [ethIconUpDown, setEthIconUpDown] = useState('fa-level-up');

    //cyx-btc
    const [cyxBtcPrice, setCyxBtcPrice] = useState(0);
    const [cyxBtcChangePercent, setCyxBtcChangePercent] = useState(0);
    const [cyxBtcVolume, setCyxBtcVolume] = useState(0);
    const [cyxBtcUpDown, setCyxBtcUpDown] = useState('color-price-up');
    const [cyxBtcPercentUpDown, setCyxBtcPercentUpDown] = useState('text-success');
    const [cyxBtcIconUpDown, setCyxBtcIconUpDown] = useState('fa-level-up');
   
     function startTimer ()  {

        onUSDTPrice(1);
        onUSDCPrice(1);
       
        BinanceApi.getBTCData()
        .then((response) => 
        {
            if(Number(response?.status)==200)
            {
                if(Number(response?.data?.lastPrice)>=btcPrice)
                {
                    setBtcUpDown('color-price-up');
                    setBtcPrice(Number(response?.data?.lastPrice).toFixed(2));
                }else 
                {
                    setBtcUpDown('color-price-down')
                    setBtcPrice(Number(response?.data?.lastPrice).toFixed(2));
                }

                if(Number(response?.data?.priceChangePercent)>=0)
                {
                    setBtcPercentUpDown('text-success');
                    setBtcIconUpDown('fa-level-up');
                }
                else
                {
                    setBtcPercentUpDown('text-danger');
                    setBtcIconUpDown('fa-level-down"');
                }

                
                setBtcChangePercent(response?.data?.priceChangePercent);
                setBtcVolume(Number(response?.data?.quoteVolume).toFixed(2));
                onBTCCPrice(Number(response?.data?.lastPrice).toFixed(2));

                //Cyx-BTC Style
            
                let priceCalc = Number(response?.data?.lastPrice).toFixed(2) /  Number(cyxPrices);
                priceCalc =  Number(priceCalc).toFixed(2);
                if(priceCalc>=cyxBtcPrice)
                {
                    setCyxBtcUpDown('color-price-up');
                    setCyxBtcPrice(Number(priceCalc));
                }
                else 
                {
                    setCyxBtcUpDown('color-price-down');
                    setCyxBtcPrice(Number(priceCalc));
                }

                if(Number(response?.data?.priceChangePercent)>=0)
                {
                    setCyxBtcPercentUpDown('text-success');
                    setCyxBtcIconUpDown('fa-level-up');
                }
                else
                {
                    setCyxBtcPercentUpDown('text-danger');
                    setCyxBtcIconUpDown('fa-level-down"');
                }

                //btc-Cyx Price
                
                onCYXPrice(Number(cyxPrices));
                
            }
            else
            {
                setBtcPrice(0);
                setBtcChangePercent(0);
                setBtcVolume(0);
                setCyxBtcPrice(0);
                onCYXPrice(0);
                onBTCCPrice(0);
            }
        })
        .catch((error) => 
        {
            setBtcPrice(0);
            setBtcChangePercent(0);
            setBtcVolume(0);
            setCyxBtcPrice(0);
            onCYXPrice(0,);
            onBTCCPrice(0);
        });

        BinanceApi.getETHData()
        .then((response) => 
        {
            //console.log(response)
            if(Number(response?.status)==200)
            {
                if(Number(response?.data?.lastPrice)>ethPrice)
                {
                    setEthPrice(Number(response?.data.lastPrice).toFixed(2));
                    setEthUpDown('color-price-up');
                }
                else 
                {
                    setEthPrice(Number(response?.data.lastPrice).toFixed(2));
                    setEthUpDown('color-price-down');
                }

                if(Number(response?.data?.priceChangePercent)>0)
                {
                    setEthPercentUpDown('text-success');
                    setEthIconUpDown('fa-level-up');
                }
                else
                {
                    setEthPercentUpDown('text-danger');
                    setEthIconUpDown('fa-level-down"');
                }
                setEthVolume(Number(response?.data?.quoteVolume).toFixed(2));
                onETHCPrice(Number(response?.data?.lastPrice).toFixed(2));
            }
            else
            {
                onETHCPrice(0);
                setEthPrice(Number(0));
                setEthChangePercent(0);
                setEthVolume(0);
            }
        })
        .catch((error) => 
        {
            setEthPrice(Number(0));
            setEthChangePercent(0);
            setEthVolume(0);
            onETHCPrice(0);
        });
      };


    //   useEffect(() => {
    //     let interval;
    //     if(websocketstatus != true)
    //     {
    //         console.log("Timer Çalıştı");
    //         interval = setInterval(startTimer, 5000);
    //         setWebSocketStatus(true,"WEBSOCKETSTATUS");            
    //     }

    //     window.onbeforeunload = function() {
    //         console.log("Servis Durduruldu");
    //         clearInterval(interval);  
    //         setWebSocketStatus(false,"WEBSOCKETSTATUS");      
    //         return true;
    //     };
    
    //     return () => {
    //         window.onbeforeunload = null;
    //     };
    //   }, [])
    

    useEffect(() => {
        const interval = setInterval(() => {
            startTimer()
        }, 1000);
        return () => clearInterval(interval);
    }, [ethPrice]);


    return (
        // <marquee behavior="alternate" direction="right" scrollamount="20" onmouseover="stop()" onmouseout="start()">

<div className="row g-3 mb-3 row-cols-1 row-cols-md-2 row-cols-lg-4">
            <div className="col">
                <div className="card">
                    <div className="card-body d-flex align-items-center">
                        <div className="flex-fill text-truncate">
                            <span className="text-muted small text-uppercase">CYX/USDT</span>
                            <div className="d-flex flex-column">
                                <div className="price-block">
                                    <span className="fs-6 fw-bold color-price-up">0.91</span>
                                    <span className="small text-muted px-2">$0.91</span>
                                </div>
                                <div className="price-report">
                                    <span className="small text-success">-0.00% <i className="fa fa-level-up"></i></span>
                                    <span className="small text-muted px-2">Volume:0 USDT</span>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div className="col">
                <div className="card">
                    <div className="card-body d-flex align-items-center">
                        <div className="flex-fill text-truncate">
                            <span className="text-muted small text-uppercase">BTC/USDT</span>
                            <div className="d-flex flex-column">
                                <div className="price-block">
                                    <span className={`fs-6 fw-bold ${btcUpDown}`}>{btcPrice}</span>
                                    <span className="small text-muted px-2">${btcPrice}</span>
                                </div>
                                <div className="price-report">
                                    <span className={`small ${btcPercentUpDown}`}>{btcChangePercent}% <i className={`fa ${btcIconUpDown}`}></i></span>
                                    <span className="small text-muted px-2">Volume:{btcVolume} USDT</span>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div className="col">
                <div className="card">
                    <div className="card-body d-flex align-items-center">
                        <div className="flex-fill text-truncate">
                            <span className="text-muted small text-uppercase">ETH/USDT</span>
                            <div className="d-flex flex-column">
                                <div className="price-block">
                                    <span className={`fs-6 fw-bold ${ethUpDown}`}>{ethPrice}</span>
                                    <span className="small text-muted px-2">${ethPrice}</span>
                                </div>
                                <div className="price-report">
                                    <span className={`small ${ethPercentUpDown}`}>{ethChangePercent}% <i className={`fa ${ethIconUpDown}`}></i></span>
                                    <span className="small text-muted px-2">Volume:{ethVolume} USDT</span>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div className="col">
            <div className="card">
                    <div className="card-body d-flex align-items-center">
                        <div className="flex-fill text-truncate">
                            <span className="text-muted small text-uppercase">CYX/BTC</span>
                            <div className="d-flex flex-column">
                                <div className="price-block">
                                    <span className={`fs-6 fw-bold ${cyxBtcUpDown}`}>{cyxBtcPrice}</span>
                                    <span className="small text-muted px-2">${cyxBtcPrice}</span>
                                </div>
                                <div className="price-report">
                                    <span className={`small ${cyxBtcPercentUpDown}`}>{cyxBtcChangePercent}% <i className={`fa ${cyxBtcIconUpDown}`}></i></span>
                                    <span className="small text-muted px-2">Volume:{cyxBtcVolume} USDT</span>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>

        // </marquee>
       
    )
}

export default ChartSmallCard;

// const mapStateToProps = ({ marketReducer }) => ({

//     marketReducer
//   })
  
// export default connect(mapStateToProps, {
//     onBTCCPrice,onETHCPrice,onCYXPrice,onUSDTPrice,onUSDCPrice
// })(ChartSmallCard);